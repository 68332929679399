import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import {
	Col,
	Row,
	Tooltip,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	FormFeedback,
	FormText,
} from "reactstrap";
import { BottomNav, CustomIHMSelect } from ".";
import { CardiovasularSymptoms, GastroIntestinalSymptoms } from "./ihm";

export default function Step2(props) {
	const [, forceUpdate] = useState(false);
	const validator = useRef(new SimpleReactValidator());

	const [form, saveForm] = useState({});
	const submitForm = (event) => {
		event.preventDefault();
		if (validator.current.allValid()) {
			form.step2complete = true;
			props.saveForm(form);
			props.nextStep();
		} else {
			validator.current.showMessages();
			forceUpdate();
		}
	};

	const onChange = (e) => {
		let val = e.target.value;
		if (e.target.type === "checkbox") {
			val = e.target.checked;
		}
		saveForm({ ...form, [e.target.name]: val });
	};

	return (
		<div className="mt-5">
			<Form onSubmit={submitForm}>
				<h5 className="mt-5">Gastro-intenstinal Disease Diagnosis:</h5>
				<Label className="text-muted" for="exampleCheckbox">
					Please select the applicable severity of the symptoms displayed by
					your pet
				</Label>
				<Row className="mt-3">
					{GastroIntestinalSymptoms.map((e, k) => (
						<Col key={k} className="mb-3" xs={6} md={6}>
							<CustomIHMSelect
								name={e.id}
								label={e.title}
								onChange={onChange}
							/>
						</Col>
					))}
				</Row>

				<h5 className="mt-5">Cardiovascular Diagnosis:</h5>
				<Label className="text-muted" for="exampleCheckbox">
					Please select the applicable severity of the symptoms displayed by
					your pet
				</Label>
				<Row className="mt-3">
					{CardiovasularSymptoms.map((e, k) => (
						<Col key={k} className="mb-3" xs={6} md={6}>
							<CustomIHMSelect
								name={e.id}
								label={e.title}
								onChange={onChange}
							/>
						</Col>
					))}
				</Row>
			</Form>

			<BottomNav previousStep={props.previousStep} nextStep={submitForm} />
		</div>
	);
}
