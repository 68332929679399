import React, { Component } from 'react'

export default class termsConditions extends Component {
    render() {
        return (
            <main> 
  <div className="position-relative my-5 pageTitle">
    <img className="img-fluid" src="/img/vetBanner.jpg" alt="inner page bg" />
    <div className="position-absolute w-100 text-center text-white page-tagline">
      <h1 className="w-70 m-auto pt-5 pb-5">Terms &amp; Conditions</h1></div></div><div className="container py-5">	
    <p>This Terms and Conditions of Service statement is a binding agreement between you and those individuals included in your account who will be using this Site (collectively, “You”, “Your” or “End User”), and ACTIV4PETS, the owner and administrator of http://www.activ4pets.com (the “Site”). ACTIV4PETS shall provide e-consultation services, second medical opinion services, medical records assistance and medical records digitization and storage, as defined on this site, to the end user. These terms and conditions, as well as any additional terms, conditions and covenants referenced in this Agreement (collectively, these “Terms”, “Terms and Conditions” or this “Agreement”), govern the use of and access to the Site and any of the services offered on the Site.</p>
    <p>REVIEW THESE TERMS AND CONDITIONS IN THEIR ENTIRETY BEFORE ACCESSING, USING OR BUYING ANY SERVICE THROUGH THE SITE.</p>
    <p>IMPORTANT: ACTIV4PETS IS NOT A PET HEALTH INSURANCE COMPANY AND IS NOT LICENSED TO SELL PET HEALTH INSURANCE.</p>
    <p><strong>ACCEPTANCE OF TERMS AND CONDITIONS</strong><br />
      Through any use of the Site, you accept, without limitation or qualification, these Terms and Conditions of Service and agree, without limitation, to the terms of our Privacy Policy. If you do not agree to be bound by these Terms and Conditions and the Privacy Policy, you should exit the Site immediately. By accessing, using or ordering services through the Site, you affirm that you have read this Agreement and understand, agree, and consent to all the terms contained herein.</p>
    <p><strong>CHANGES AND MODIFICATION TO THESE TERMS AND CONDITIONS OF SERVICE</strong><br />
      ACTIV4PETS reserves the right, at its sole discretion, to change, modify, add, or remove these Terms and Conditions (or any portion thereof) and its other policies and agreements related to the Site at any time and in any manner, including the Privacy Policy, without prior notice. Your use of the Site after any changes are made signifies your acceptance to be bound by these Terms and Conditions and the Privacy Policy as they exist at that time. Your use of this Site or any of ACTIV4PETS’ Services will be conditioned upon the Terms and Conditions of Service in force at the time of use. You can always review the most current version of these Terms and Conditions on this web page. ACTIV4PETS does not assume any obligation to provide you with notice of any change to these Terms and Conditions. These Terms and Conditions may not be amended by you under any circumstances, either orally or in writing.</p>
    <p><strong>USING THE SERVICE AND SUBMITTING A SUBSCRIPTION</strong><br />
      You have elected to utilize the Site as a paid subscriber. ACTIV4PETS offers certain services for sale (collectively, the “Services”) on the Site. ACTIV4PETS reserves the right to modify the prices or other Terms charged for these Services, or to add or remove any Service from the Site, at any time without prior notice to you. Price changes shall not apply to services purchased prior to the price change.</p>
    <p><strong>SUBSCRIPTION AND CANCELLATION FEES</strong><br />
      User shall pay to ACTIV4PETS the annual subscription fees described in this ACTIV4PETS Site for the particular Service(s) you wish to purchase. The compensation payable to ACTIV4PETS shall be due upon registration. Notwithstanding the foregoing, you shall have the right to cancel the subscription and, unless canceled within 30 days of initial payment the subscription fee shall be non-refundable.</p>
    <p><strong>CANCELLATION POLICY</strong><br />
      If you wish to cancel a subscription, it must be done by sending an email to support@activ4pets.com. You will be asked to agree to this cancellation policy prior to proceeding with the subscription of the membership.</p>
    <p>Subscriptions canceled within 30 days of initial membership payment shall be entitled to a full refund.</p>
    <p>If you cancel the subscription after 30 days of membership payment, the subscription fee shall be non-refundable.</p>
    <p>Upon cancellation of the membership, your account will no longer be accessible.</p>
    <p>ACTIV4PETS may terminate this Agreement (i) if you commit a material breach of these Terms and Conditions and fail to remedy that breach within thirty (30) days of receipt of a written notice of such material breach, or (ii) if you fail to pay an invoice when due.</p>
    <p>You may terminate this Agreement (with no penalty) if ACTIV4PETS commits a material breach of this Agreement and fails to remedy that breach within thirty (30) days of receipt of written notice of material breach.</p>
    <p><strong>SERVICES</strong><br />
      ACTIV4PETS makes available to each end user and their pet’s attending veterinarian medical opinion services to assist you and your veterinarian to better understand and assess your medical condition. ACTIV4PETS arranges for you to receive a Second Medical Opinion (“SMO”), as defined below, from one or more contracted veterinarians (the “Veterinarians”) licensed to practice medicine in the state, territory, or foreign country of residence of the end user.</p>
    <p><strong>E-Consultation</strong><br />
      ACTIV4PETS makes available to its end Users E-Consultation services whereby ACTIV4PETS arranges for you to receive an E-Consultation about your pet from a veterinarian under contract with ACTIV4PETS.</p>
    <p>ACTIV4PETS shall arrange for the E-Consultation Report to be available to you within at least five (5) days after the E-Consultation takes place.</p>
    <p><strong>Accuracy of Information</strong></p>
    <p>You agree to provide to ACTIV4PETS true and complete copies of your pet’s veterinary medical records (“Records”) to receive E-Consultation Services and any other additional medical information and/or documents in your possession or control that may be required.</p>
    <p>You acknowledge that you will furnish to ACTIV4PETS and the veterinarian(s) your pet’s health information in accordance with the Privacy Policy of ACTIV4PETS. AS SUCH, ACTIV4PETS AND ITS VETERINARIANS SHALL RELY ON YOU TO PROVIDE COMPLETE, ACCURATE, AND TIMELY INFORMATION AND SHALL RELY EXCLUSIVELY ON SUCH INFORMATION PROVIDED BY YOU IN CONDUCTING THE E-CONSULTATION, WITHOUT FURTHER INVESTIGATION OR INQUIRY.</p>
    <p><strong>Indemnity</strong></p>
    <p>ACTIV4PETS abides by all state regulations in offering these Services. However, if you do not reside in a state that permits an E-Consultation, ACTIV4PETS may not be able to process the User’s E-Consultation request.</p>
    <p>ACTIV4PETS assumes no responsibility for (i) following up or ensuring that you take any action set forth in the recommendation(s) resulting from your pet’s E-Consultation; (ii) recommending any veterinary care professionals; or (iii) otherwise responding to the information included in your pet’s E-Consultation.</p>
    <p><strong>No Client-Professional Relationship</strong></p>
    <p>Any information provided to you by ACTIV4PETS does not constitute veterinary or any other professional advice. No client-professional relationship shall be formed among you and ACTIV4PETS as a result of you using E-Consultation Services.</p>
    <p>The content of the Activ4Pets site and any information provided to you as part of your use of the E-Consultation Services are for informational purposes only, and are not a substitute for in-person assessment or specific professional veterinary advice, diagnosis, or treatment. Always seek the advice of your veterinarian or other qualified pet health provider with any questions you may have regarding a pet’s veterinary medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on the Activ4Pets Site.</p>
    <p>The laws, regulations, other governing authorities, standards, practices and procedures that apply to your particular question or situation may differ depending on your location and information typically discovered through in-person assessments or visits. Professionals available through the Site may be licensed, certified, educated, employed by or have experience in only particular jurisdictions or only within particular fields. Communications with veterinary professionals through E-Consultation Services are inherently limited, as described above, and do not involve in-person assessments or visits, and do not include safeguards and procedures typical of in-person assessments and visits.</p>
    <p>ACTIV4PETS does not recommend or endorse any specific tests, veterinarians, products, procedures, opinions, or other information that may be mentioned on the Site. Reliance on any information provided by ACTIV4PETS, ACTIV4PETS employees or contractors, or other visitors to the Site is solely at your own risk.</p>
    <p><strong>SECOND MEDICAL OPINION SERVICES</strong><br />
      ACTIV4PETS makes available to its end Users and their attending veterinarian veterinary medicine opinion services to assist the end User and their veterinarian to better understand and assess medical conditions. ACTIV4PETS arranges for you to receive a Second Medical Opinion (“SMO”) from one or more veterinarians under contract with ACTIV4PETS (“Veterinarians”).</p>
    <p>ACTIV4PETS shall arrange for the delivery of the SMO Report to you within at least forty-eight (48) hours from the date ACTIV4PETS determines the Records are sufficiently complete to enable it to begin a review, or within at least five (5) days in the event the Veterinarian requires additional information.</p>
    <p><strong>Definitions</strong></p>
    <p>The term “Second Medical Opinion”, “SMO” shall mean (i) an independent review by a Veterinarian (or team of Veterinarians) of your Pet Health Records (“PHR”) provided by you to ACTIV4PETS, including without limitation, laboratory tests and x-rays; (ii) the providing of information, if any, to you or your designee with any diagnosis or treatment; and (iii) the expression of a written statement by the Veterinarian as to whether the Veterinarian agrees with the diagnosis assigned, and the course and plan of treatment provided or to be provided, to your pet.</p>
    <p><strong>Accuracy of Information</strong></p>
    <p>You agree to provide to ACTIV4PETS true and complete copies of your pet’s veterinary medical records (“Records”) to receive SMO Services and any other additional medical information and/or documents in your possession or control that may be necessary in the assessment of any prior diagnosis.</p>
    <p>You acknowledge that you will furnish to ACTIV4PETS your pet’s health information in accordance with the Privacy Policy of ACTIV4PETS. AS SUCH, ACTIV4PETS AND ITS VETERINARIANS SHALL RELY ON YOU TO PROVIDE COMPLETE, ACCURATE, AND TIMELY INFORMATION AND SHALL RELY EXCLUSIVELY ON SUCH INFORMATION PROVIDED BY YOU IN FORMULATING AN SMO, WITHOUT FURTHER INVESTIGATION OR INQUIRY.</p>
    <p><strong>Indemnity</strong></p>
    <p>ACTIV4PETS abides by all state regulations in offering these Services. However, if you do not reside in a state that permits an electronic SMO, ACTIV4PETS may not be able to process the User’s SMO request.</p>
    <p>ACTIV4PETS assumes no responsibility for (i) following up or ensuring that you take any action set forth in the recommendation(s) contained in any SMO, (ii) recommending any health care professionals, or (iii) otherwise responding to the information included in your SMO.</p>
    <p><strong>No Client-Professional Relationship</strong></p>
    <p>Any information provided to you by ACTIV4PETS does not constitute veterinary or any other professional advice. No client-professional relationship shall be formed among you and ACTIV4PETS as a result of you using Second Veterinary Opinion Services.</p>
    <p>The content of the Activ4Pets site and any information provided to you as part of your use of the Second Veterinary Opinion Services are for informational purposes only, and are not a substitute for in-person assessment or specific professional veterinary advice, diagnosis, or treatment. Always seek the advice of your veterinarian or other qualified pet health provider with any questions you may have regarding a pet’s veterinary medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on the Activ4Pets Site.</p>
    <p>The laws, regulations, other governing authorities, standards, practices and procedures that apply to your particular question or situation may differ depending on your location and information typically discovered through in-person assessments or visits. Professionals available through the Site may be licensed, certified, educated, employed by or have experience in only particular jurisdictions or only within particular fields. Communications with veterinary professionals through Second Veterinary Opinion Services are inherently limited, as described above, and do not involve in-person assessments or visits, and do not include safeguards and procedures typical of in-person assessments and visits.</p>
    <p>ACTIV4PETS does not recommend or endorse any specific tests, veterinarians, products, procedures, opinions, or other information that may be mentioned on the Site. Reliance on any information provided by ACTIV4PETS, ACTIV4PETS employees or contractors, or other visitors to the Site is solely at your own risk.</p>
    <p><strong>MEDICAL RECORDS ASSISTANCE</strong><br />
      ACTIV4PETS makes available to its end Users Veterinary Medical Records Assistance services. ACTIV4PETS will gather your pet’s veterinary medical records from your pet’s current or previous veterinarian(s), scan related paper records, copies of documents, records, images, and information, review paper or electronic records in order to detect specific problems which the User may have overlooked, and electronically upload pet’s veterinary medical records to the appropriate sections of the ACTIV4PETS PHR. You are entitled to four (4) free Medical Records Assistance requests for up to four (4) Veterinarians but will be charged a fee of $25.00 per additional Veterinarian request.</p>
    <p>You agree to (1) complete ACTIV4PETS’ required Authorization for Release of Protected Pet Health Information form. (2) By signing ACTIV4PETS’ Authorization for Release of Protected Pet Health Information form, you authorize ACTIV4PETS to receive your pet’s medical records for the purpose of digitizing, sorting and uploading. (3) You acknowledge that additional fees may be required by your Veterinarian(s) and/or third party health administrators.</p>
    <p>By signing and submitting the Authorization for Release of Protected Pet Health Information form, you agree to give ACTIV4PETS’ Medical Records Administrator or Medical Director access to your account to upload and store the medical records received on your behalf.</p>
    <p><strong>MEDICAL RECORDS DIGITALIZATION AND STORAGE</strong><br />
      ACTIV4PETS makes available to its end Users a service of digitalization and storage of your pet’s veterinary medical records.</p>
    <p>You have the opportunity to upload, store and access your health records and information, including copies of documents, records, images, and information submitted by you (“Your Pet’s Data”). ACTIV4PETS will maintain Your Pet’s Data on a secure database. Electronic storage allows the immediate viewing of Your Pet’s Data by you or your authorized health care providers.</p>
    <p>You are responsible for uploading Your Pet’s Data to the Site. ACTIV4PETS will not be responsible for returning any original or other documents comprising your Records provided to ACTIV4PETS. To the extent permitted by law, any documents or images you submit or direct to be submitted on your behalf to ACTIV4PETS shall become ACTIV4PETS’ property and cannot be returned to you. ACTIV4PETS assumes no responsibility for the quality of X-rays or other personal medical images archived on the Site or for medical decisions, which are based on your Records, including any images or reports. The documents you submit or direct to submit to ACTIV4PETS may be destroyed at ACTIV4PETS’ discretion.</p>
    <p>By agreeing to these Term and Conditions, you grant ACTIV4PETS, its employees, officers, directors, agents, and contractors, and all other persons or entities involved in the operation of this ACTIV4PETS Site, the right to access, transmit, receive, monitor, retrieve, store, maintain, and use your Data and any other information you provided in connection with the establishment and maintenance of your ACTIV4PETS account to provide the SMO services, the veterinary medical records assistance and the veterinary medical records digitalization and storage services (collectively “Services”) and to operate the ACTIV4PETS Site, including but not limited to, converting documents received on your behalf to an electronic format.</p>
    <p>You are solely responsible for the accuracy and completeness of any documents or data you submit to ACTIV4PETS. You are also responsible for occasionally reviewing Your Pet’s Data to ensure accuracy.</p>
    <p>You agree that ACTIV4PETS is not obligated to retain, maintain, deliver or allow access to your Records upon the termination of your subscription.</p>
    <p><strong>SITE USAGE</strong><br />
      By registering to use the ACTIV4PETS Site, you will create a personal profile account and may have access to the posting feature. By posting any comments or other material on the ACTIV4PETS Site (each, a “Posting”), you give ACTIV4PETS the irrevocable right to reproduce, distribute, publish, display, edit, modify, create derivative works from, and otherwise use your posting for any purpose, in any form, and on any media without consideration. By submitting communications or content to the public areas, you agree that such posting is non-confidential for all purposes.</p>
    <p>You also agree that:</p>
    <p>You will maintain the security of all your identification, password and other confidential information relating to the use of your account.</p>
    <p>You will not copy, modify, or display or download ACTIV4PETS’ name, trademarks, or logo or any text, copyrighted works, graphic images, or other proprietary content from the Site.</p>
    <p>You will not upload or transmit any communications or content of any type that infringes upon or violates the rights of any party.</p>
    <p>You will not redeliver any of the pages, text, images, or other content of ACTIV4PETS Site using “linking,” “deep linking,” or “framing” technology.</p>
    <p>You will not create a link from a website to any page of the ACTIV4PETS Site unless you or the operator of the other website has executed ACTIV4PETS’ then-standard agreement for the granting of permission to establish such a link.</p>
    <p>You will not include any ACTIV4PETS mark, the name of any of ACTIV4PETS personnel, or any variation of any of the foregoing as a Meta tag, hidden textual element, or any other indicator that may create a false or misleading impression of affiliation, sponsorship, or endorsement between any user and/or website and ACTIV4PETS.</p>
    <p>You will not use devices (including software) that are designed to provide repeated automated access to the ACTIV4PETS Site other than those made generally available by ACTIV4PETS, or probe, scan, or test the vulnerability of any system or network related in any way to this Site.</p>
    <p>You will not use the ACTIV4PETS Site or its Services for any purpose in violation of local, state, federal or international laws.</p>
    <p>You will not use the ACTIV4PETS Site or its Services as a means to distribute advertising or other unsolicited material to any third party.</p>
    <p>You will not post material that is unlawful, obscene, defamatory, threatening, harassing, abusive, slanderous, hateful or embarrassing to any other person or entity as determined by ACTIV4PETS in ACTIV4PETS sole discretion.</p>
    <p>You will not impersonate another person or create any false account information or health record for any other person.</p>
    <p>You will not post advertisements or solicitations of business.<br />
      You will not post chain letters or pyramid schemes.</p>
    <p>You will not allow any other person or entity to use User identification for posting or viewing comments.</p>
    <p>You will not post the same note more than once or post “spam” or use the ACTIV4PETS Site to create any nuisance.</p>
    <p>You will not hold ACTIV4PETSor any of ACTIV4PETS affiliates, successors, assigns or licensees, together with any of their respective officers, directors and employees, liable for damages including (but not limited to) loss of wages, revenue or business because of any services related to or provided by this Site.</p>
    <p>You will not submit any media that contains your personally identifiable information (such as User’s name, phone number, email address or website URL) or that of anyone else except as otherwise requested by ACTIV4PETS as otherwise permitted in accordance with this Agreement.</p>
    <p>You will not upload any media on the ACTIV4PETS Site without the express written permission from the owner of such media content.</p>
    <p>You will not upload media of any kind that contain expressions of hate, abuse, offensive images, obscenity, pornography, sexually explicit content or any material that could give rise to any civil or criminal liability under applicable law or regulations or that otherwise may be in conflict with these Terms and Conditions.</p>
    <p>You will not upload any media that contains software viruses or any other computer code, files or programs designated to interrupt, destroy or limit the functionality of any computer software or this Site.</p>
    <p>You agree to comply with all your responsibilities and obligations as stated in these Terms and Conditions.</p>
    <p>ACTIV4PETS reserves the right, but is not obligated to: remove communications that are abusive, illegal or disruptive, or that otherwise fail to conform to these Terms and Conditions; terminate your access to the Site or any feature on the Site upon any breach of these Terms and Conditions; and edit or delete any communications posted on the Site, regardless of whether such communications violate these standards.</p>
    <p>By uploading any media to a public area of the Site, including, but not limited to, a photo or video, (a) you grant ACTIV4PETS a perpetual, non-exclusive, worldwide, royalty-free license to use, copy, print, display, reproduce, modify, publish, post, transmit and distribute the media and any material included in the media in any format now existing or created in the future; (b) you certify that any person featured in the submitted media (or, if a minor, his/her parent/legal guardian) authorizes ACTIV4PETS to use, copy, print, display, reproduce, modify, publish, post, transmit and distribute the media and any material included in such media in any format now existing or created in the future; and (c) you agree that you will indemnify ACTIV4PETS against any damages, losses, liabilities, judgments, costs or expenses (including actual attorneys’ fees and costs) arising out of a claim by a third party relating to any media you have posted.</p>
    <p>While ACTIV4PETS welcomes your feedback on the Site, ACTIV4PETS must make the final decisions about the content that is posted on the Site to make sure it conforms to ACTIV4PETS objectives and to the objectives ACTIV4PETS identifies for ACTIV4PETS Users. If you are dissatisfied with the Site, your only remedy is to stop using the Site. ACTIV4PETS follows a standard set of editorial procedures for information that ACTIV4PETS publishes on the Site.</p>
    <p>When deciding to take advice on the Site and/or hire any professional based on information on the Site, you should perform independent research as to the accuracy of the credentials expressed in the content, provided in the professional’s public profile, or existing as part of any other material found on the Site or the Services.</p>
    <p><strong>USER ACCOUNT, PASSWORD, AND SECURITY</strong><br />
      You must establish a user name and password upon completing the registration process. You are responsible for maintaining the confidentiality of the Access Code and are fully responsible for all activities that occur under your Access Code. You are responsible for taking all reasonable steps to ensure that no unauthorized person(s) shall have access to your Access Code. It is your sole responsibility to control the dissemination of your Access Code, to authorize, monitor, and control access to and use of your Records, Account, and Access Code, and to promptly inform ACTIV4PETS of any need to deactivate or reset an Access Code.</p>
    <p>You agree to immediately notify ACTIV4PETS of any unauthorized use of your Access Code, password, or account information or any other breach of security; and, (b) ensure that you exit from your account at the end of each session. ACTIV4PETS shall not be liable for any loss or damage arising from your failure to comply with these Terms and Conditions.</p>
    <p><strong>INDEMNITY</strong><br />
      You agree that you are solely responsible for anything you post on the Site and that you will defend, indemnify, and hold ACTIV4PETS, its officers, directors, affiliates, employees, agents, licensors, and suppliers harmless from and against any claims, actions, demands, liabilities, and settlements including, without limitation, actual legal and accounting fees resulting from, or alleged to result from, your violation of these Terms and Conditions or any claims based upon your Postings on the Site or your use of the Services.</p>
    <p>You acknowledge and agree that the SMO Report contains only our Veterinarian’s conclusions and information regarding the diagnosis and treatment or proposed diagnosis and treatment, and that reasonable minds may differ on such diagnoses and treatment or proposed diagnoses and treatment. In addition, you acknowledge and agree that the Veterinarians are independent contractors of ACTIV4PETS, and that, as a result, ACTIV4PETS has no control over, input into, or responsibility for, the matters contained in the SMO Report. You further acknowledge and agree that the SMO Report states a mere professional opinion and that you and your attending/treating veterinarian are free to disregard the SMO Report in their sole and absolute discretion.</p>
    <p>You acknowledge and agree that the provision of any Services to you, pursuant to your subscription, does not create a veterinarian-patient relationship between the respective Veterinarian(s) and you. Accordingly, on your own behalf and on behalf of your heirs, executors and personal representatives, hereby releases and discharges ACTIV4PETS, all parent, subsidiary and/or affiliated companies, as well as its and their successors, assigns, officers, owners, directors, agents, representatives, attorneys, and employees, and the Veterinarians, (all of whom are referred to throughout this Agreement as “ACTIV4PETS Releases”), of and from all claims in tort, demands in tort, tort causes of action, tort damages, tort losses, and expenses in tort, as a result of any alleged malpractice or alleged professional liability claim(s) arising out of or related to this Agreement. In addition, you hereby agree that neither you, nor your heirs, executors and personal representatives, will file any tort action, or assign to others the right to file any tort action, or make any claims in tort, as a result of any alleged malpractice or alleged professional liability against ACTIV4PETS arising out of or related to this Agreement. The foregoing shall not be deemed or construed to prevent you from making any claim against ACTIV4PETS for breach of contract for the failure of ACTIV4PETS to perform its obligations under these Terms and Conditions.</p>
    <p><strong>HEADINGS</strong><br />
      The headings to the sections in this Agreement shall not affect the construction or interpretation of this Agreement.</p>
    <p><strong>ASSIGNMENT</strong><br />
      You agree that these Terms and Conditions and all incorporated agreements between ACTIV4PETS and you, may be assigned by ACTIV4PETS, in its sole discretion.</p>
    <p><strong>WAIVER AND SEVERABILITY OF TERMS</strong><br />
      The failure of ACTIV4PETS to exercise or enforce any right or provision of the Terms and Conditions shall not constitute a waiver of such right or provision. If any of the provisions of this Agreement are held to be unenforceable by a court or other tribunal of competent jurisdiction, then such provisions (deemed unenforceable) shall be limited or eliminated to the minimum extent necessary and this Agreement shall otherwise remain in full force and effect.</p>
    <p><strong>NO RIGHT OF SURVIVORSHIP AND NON-TRANSFERABILITY</strong><br />
      You agree that your ACTIV4PETS account is non-transferable by you and any rights to your Access Code or contents within yours account terminate upon your death. Upon receipt of a copy of a death certificate, your account may be terminated and all contents therein permanently deleted.</p>
    <p><strong>PROPRIETARY RIGHTS</strong><br />
      You acknowledge and agree that the Site and any necessary software used in connection with the Site (“Software”) contains proprietary and confidential information that is protected by applicable intellectual property and other laws. Title to the Site and the Software remains with ACTIV4PETS or the respective owner, and all rights not expressly granted herein are reserved to ACTIV4PETS. You further acknowledge and agree that any content contained in sponsor advertisements or information presented to you through the Site is protected by copyrights, trademarks, service marks, patents or other intellectual property rights and laws and that all computer systems, procedures, and databases created, operated, maintained or accessed by ACTIV4PETS in connection with the Site contain proprietary and confidential information of substantial economic value to ACTIV4PETS. This information includes operations, systems, products, business relationships, marketing approaches, software, hardware, codes, trade secrets, information technology, information systems, plans and projections, as well as concepts and ideas. Any unauthorized use, misuse, or disclosure of such information is strictly prohibited and may result in civil liabilities and criminal penalties under applicable law.</p>
    <p>Except as expressly authorized by ACTIV4PETS or advertisers, as applicable, you agree not to modify, rent, lease, loan, sell, distribute or create derivative works based on the Site or the Software, in whole or in part. You may download and reprint a single copy of the materials from the Site and/or the Services for you own personal, noncommercial use. You agree not to modify the Software in any manner or form, or to use modified versions of the Software, including (without limitation) for the purpose of obtaining unauthorized access to the Site. You agree not to access the Site nor shall you otherwise create or attempt to obtain the source code of the Software.</p>
    <p><strong>FOR INTERNATIONAL USERS</strong><br />
      ACTIV4PETS makes no claim that the content on this Site may be viewed legally outside of the United States of America. Access to the Site may not be legal in certain countries. If you access this Site from outside the United States, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction. You agree to comply with all applicable laws regarding the transmission of technical data exported from the United States or imported into the country from which you are accessing the Site.</p>
    <p><strong>COMPLETE AGREEMENT</strong><br />
      These Terms and Conditions, the Privacy Policy, and all associated and incorporated agreements (collectively the “Agreements”) which together set forth the relationship between you and ACTIV4PETS are intended to be read concurrently. The Agreements constitute the entire agreement between you and ACTIV4PETS with respect to the use of the Site and its Services.</p>
    <p><strong>USER AND ACTIV4PETS</strong><br />
      Electronic acceptance via the Site, facsimile signatures, and electronic signatures will have the same legal effect as original signatures and are binding on the parties. Upon acceptance, you have agreed to be bound by all of the terms and conditions of this Agreement. Your relationship with ACTIV4PETS is one of independent parties to a contractual relationship and should not be construed to create a joint venture, partnership, or other affiliation.</p>
    <p>ACTIV4PETS collects personal information that you choose to share with ACTIV4PETS when you register with the Site. This information may include your e-mail address, your health interests and your answers to questions about your health status. ACTIV4PETS uses this information to tailor ACTIV4PETS’ Services to you and this information is saved to ACTIV4PETS’ database, enabling you to use the personalized features of the Site on a recurring basis. This saved information is collected only with your permission and may be updated or deleted by you at any time. If you choose to not provide the information required for registration, you may not register with the Site and you will not be able to personalize the Site.</p>
    <p><strong>THIRD PARTIES</strong><br />
      The ACTIV4PETS website is hosted by third parties independent of ACTIV4PETS. Those persons are solely responsible for their performance, conduct, actions and omissions. Furthermore, some of the material on the ACTIV4PETS site is provided by third parties independent of ACTIV4PETS and ACTIV4PETS shall not be held responsible for any such third-party material. ACTIV4PETS disclaims any responsibility for or liability related to such third party material. Any question, complaints or claims related to any product should be directed to the appropriate third party.</p>
    <p><strong>MODIFICATIONS</strong><br />
      ACTIV4PETS reserves the right to make changes, modifications and alterations to the ACTIV4PETS Site and the content without notification to you. As a registered user, you accept the obligation to periodically review these Terms and Conditions for changes or modifications. Continued use of ACTIV4PETS Services shall be deemed acceptance of these Terms and Conditions as modified and you agree to be bound by any such changes.</p>
    <p><strong>APPLICABLE LAW / DISPUTES</strong><br />
      By using ACTIV4PETS Services, you agree that the laws of the State of Florida, without regard to principles of conflict of laws, will govern these Terms and Conditions and any dispute that might arise between User and ACTIV4PETS, its Veterinarians, staff or other affiliates. You expressly agree that exclusive jurisdiction for any dispute with ACTIV4PETS, its affiliates, employees, subsidiaries, contractors, officers and directors, resides in the courts of Miami-Dade County, Florida and also agree to and expressly consent to the exercise of personal jurisdiction in the courts of Miami-Dade County, Florida in connection with any claim involving ACTIV4PETS, its affiliates, employees and subsidiaries.</p>
    <p><strong>COPYRIGHT AND TRADEMARKS</strong><br />
      All content included on the ACTIV4PETS Site, including, but not limited to, text, photographs, graphics, button icons, images, artwork, names, logos, trademarks, service marks and data (“the Content”), in any form including the compilation thereof, are protected by U.S. and international copyright law and conventions. The Content includes both Content owned or controlled by ACTIV4PETS and Content owned or controlled by third parties and licensed to ACTIV4PETS. Except as set forth below, direct or indirect reproduction of the Content, in whole or in part, by any means, is prohibited without the express written consent of ACTIV4PETS.</p>
    <p><strong>DISCLAIMERS AND LIMITATIONS</strong><br />
      By using ACTIV4PETS Services, you agree to abide by these Terms and Conditions and those additional terms and conditions posted on the ACTIV4PETS website from time to time:<br />
      You agree that the following disclaimers form an essential part of the Terms and Conditions.</p>
    <p>You agree that ACTIV4PETS’ liability pursuant to these Terms and Conditions shall not under any circumstances, and regardless of whether the liability arises out of contract or tort, exceed the amount of the most recent subscription fee you have paid to ACTIV4PETS.</p>
    <p>The service provided by ACTIV4PETS through the review of your pet’s Veterinary Medical Records is different from the diagnostic services typically provided by a veterinarian during a face-to-face visit. Providing this service will not have the benefit of information that could be obtained by examining your pet and observing its physical condition. ACTIV4PETS encourages you to share the SMO with your attending veterinarian. You should always consult with your veterinarian or other appropriate health care professionals before making any changes in diet, physical activity, medications and/or therapy.</p>
    <p>By requesting a SMO Service, you acknowledge and agree that:</p>
    <p>The SMO received will be limited to the attending veterinarian’s review of your personal health records;</p>
    <p>The SMO is not intended to replace a full medical evaluation or face-to-face visit with a veterinarian;</p>
    <p>The Veterinarian may not have important information that is usually obtained through a physical examination;</p>
    <p>You will communicate with your veterinarian after receiving the SMO;</p>
    <p>The absence of physical examination may affect the Veterinarian’s ability to give a complete opinion on Pet’s condition, disease or injury.</p>
    <p>SMO Services are for medical purposes only and not for use in any related litigation or malpractice matters. We suggest that you contact an attorney and/or related medical professional in your state, territory, or country of residence for further assistance in such matter.</p>
    <p>Nothing contained in the ACTIV4PETS Site is or should be considered, or used as a substitute for, diagnosis or treatment, nor prescription.</p>
    <p>By engaging ACTIV4PETS’ Services, you acknowledge and agree to assume the risk of these limitations.</p>
    <p>You further understand that no warranty or guarantee has been made to you concerning any particular result or cure of any condition.</p>
    <p>ACTIV4PETS makes no warranty or guarantee that the ACTIV4PETS website will be uninterrupted, timely, and secure or error free.</p>
    <p>ACTIV4PETS also does not warrant or guarantee that medical records hosted on ACTIV4PETS website will be free of infections or viruses, worms, Trojan horses or other code that contain contaminating or destructive properties.</p>
    <p>You agree that ACTIV4PETS does not constitute a service that targets any one community, user group, business or industry.</p>
    <p>You assume total responsibility and risk for the use of the information provided by ACTIV4PETS. The advice is provided on an “as is” and “as available” basis. Neither ACTIV4PETS nor any of its officers, members, employees, agents, contractors or Veterinarian makes any express or implied warranties, representations or endorsements whatsoever with regard to any information or service provided through the ACTIV4PETS website or through the internet generally; and under no circumstances will ACTIV4PETS or any of its officers, members, employees, agents, contractors or Veterinarians be liable for any direct, indirect, incidental, special, punitive or consequential damages that result in any way from the use of or the inability to use the services or any parts thereof, or the reliance on or use of information, or services provided, or that result from mistakes, omissions, interruptions, deletion or any failure or performance. Neither ACTIV4PETS nor its affiliates warrants that the services will be uninterrupted or error-free or that any information, software or other material accessible on this site is free of viruses of other harmful components.</p>
    <p><strong>WARRANTIES AND LIMITATION OF LIABILITY</strong><br />
      Certain links in the Site may link you to other sites. The linked sites are not under the control of ACTIV4PETS and ACTIV4PETS is not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. ACTIV4PETS is not responsible for webcasting or any other form of transmission received from any linked site. ACTIV4PETS is providing these links to you only as a convenience, and the inclusion of any link does not imply a referral by ACTIV4PETS, an endorsement of or sponsorship by ACTIV4PETS, or affiliation by ACTIV4PETS with, the linked site, its services, and its owner, operator, sponsoring organization or internet provider. This Site may contain advertisements by third parties and the Site may receive royalties from such advertisers.</p>
    <p>ACTIV4PETS is not liable to you or any third party for any decision made or action taken by you or anyone else based on information contained on the site or provided through the services, or due to reliance upon information contained on the site or provided through the services.</p>
    <p>To the fullest extent permitted by law, any claims arising in connection with your use of the site and any of its content must be brought within one (1) year of the date the event giving rise to such action occurred.</p>
    <p><strong>MONITORING OF SITE</strong><br />
      ACTIV4PETS reserves the right to monitor any and all use of this Site. ACTIV4PETS is under no obligation to do so and assumes no responsibility or liability arising from ACTIV4PETS doing so or omission to do so. ACTIV4PETS may monitor your use of this Site to evaluate the quality of service you receive, your compliance with these Terms and Conditions, the security of this Site, or for other lawful reasons. You agree that you will not be entitled to any cause of action or other right with respect to ACTIV4PETS concerning such monitoring activities.</p>
    <p><strong>CONTACT ACTIV4PETS</strong><br />
      If you have any questions or concerns, you may contact ACTIV4PETS Customer Service department as indicated below. ACTIV4PETS will attempt to respond to your questions or concerns promptly.</p>
    <p>Activ4Pets, LLC<br />
      2100 Coral Way, Miami, FL 33145<br />
      Tel: 855-738-3282<br />
      E-mail: admin@activ4pets.com</p>
    <p>Updated: 2/05/2016</p>
  </div>
</main>

        )
    }
}
