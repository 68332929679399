import React from "react";
import { Link } from "react-router-dom";
import { getUserId } from "utils";

export default function IHMWelcome(props) {
	return (
		<div>
			<div className="intro text-muted">
				<p className="mb-4 lead text-secondary">
					Welcome to the Activ4Pets Pet Health Risk Estimator
				</p>
				<p className="" style={{ textDecoration: "underline" }}>
					The risk estimator evaluates the general health of your pet.
				</p>
				<p className="">
					Cardiovascular disease (CVD) is the leading cause of death worldwide,
					accounting for 45% of all deaths (>4 million) in Europe in 20161,2.
					Dogs may be beneficial in reducing cardiovascular risk by providing a
					non-human form of social support and increasing physical activity.
				</p>
				<p className="" style={{ textDecoration: "underline" }}>
					How is the Health Estimator used? 
				</p>
				<p className="">
					The Activ4Pets Health Risk Estimator determines a risk score,
					expressed as a percentage, to be used for evaluating your pets health
					with diagnostic recommendations.
				</p>
			</div>
			<div className="cta my-5 text-center">
				<button
					className="btn btn-primary mb-3 px-5 py-2 text-light"
					onClick={() => props.setStart(true)}
				>
					Start
				</button>
				{!getUserId() && (
					<div>
						<Link to="/login" className="text-secondary">
							I have already taken the test
						</Link>
					</div>
				)}
			</div>
		</div>
	);
}
