import React, { Component } from "react";
import MyProfile from "../components/MyProfile";
import MyPets from "pages/components/MyPets";
import SectionHead from "../components/sectionHead";

export default class UserMyProfile extends Component {
	render() {
		return (
			<div>
				<div className="mainc container-fluid">
					<div>
						<SectionHead title="Settings" />
						<div className="ml-2 w-75">
						    <div className="">
    						    <h5 className="mb-4 text-muted">My Pets</h5>
        						<div className="">
        							<MyPets mode="update" />
        						</div>
    						</div>
                            <hr className="py-2 ml-0" />
    						<div className="">
    						    <h5 className="mb-2 text-muted">Profile</h5>
        						<div className="">
        							<MyProfile />
        						</div>
    						</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
