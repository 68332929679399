import React, { Component } from "react";
import isEmpty from "utils/isEmpty";
import CRUDUpdate from "./update";
import Button from "pages/components/form/Button";
import { confirmAlert } from "react-confirm-alert";
import { isSMDevice } from "utils";

// It populates all the data, and then provides crud callbacks
export default class CRUDIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditView: false,
            RecordData: null
        };

        this.onEditClick = this.onEditClick.bind(this);
        this.onUpdateRow = this.onUpdateRow.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tableConfig !== this.props.tableConfig)
            this.setState({ showEditView: false });
    }

    onEditClick(id) {
        let rowData = null;
        if (id) {
            // id will be null for add
            const { tableData } = this.props;
            let [row] = tableData.filter(i => i.id === id);
            row.details = row.details || []; // PHR sends data in deatils object need to fix it
            rowData = { ...row, ...row.details };
        }
        this.setState({
            RecordData: rowData,
            showEditView: !this.state.showEditView
        });
    }

    onDeleteClick(id) {
        confirmAlert({
            message: `Are you sure you want to delete this item?`,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        if (this.props.onDeleteRow) this.props.onDeleteRow(id);
                    }
                },
                {
                    label: "No",
                    onClick: () => console.log("escaped")
                }
            ]
        });
    }

    onUpdateRow(id, payload) {
        if (this.props.onUpdateRow)
            this.props.onUpdateRow(id, payload).then(r => this.setState({ showEditView: !this.state.showEditView }));
    }

    render() {
        const { showEditView, RecordData } = this.state;

        const { tableConfig, tableData } = this.props;

        // filter for only visible elements
        const filteredLi = tableConfig && tableConfig.filter(i => i.visible);
        const tableConfigTitles = filteredLi && filteredLi.map(i => i.title);
        const tableConfigNames = filteredLi && filteredLi.map(i => i.name);

        const AddBtn = () => (
            <Button
                btnCls="btn-outline-primary px-4"
                iconCls="fa-plus"
                value="Add New"
                onClick={e => this.onEditClick(null)}
            />
        );

        return (
            <div>
                {!showEditView &&
                    (isEmpty(tableData) ? (
                        <div className="col p-3 border">
                            <div className="m-auto py-4 text-center">
                                <div className="h5 mb-3 text-muted">No Records Found</div>
                                <AddBtn />
                            </div>
                        </div>
                    ) : (
                        <div className="mt-4">
                            <div className="my-2 d-flex justify-content-end">
                                <AddBtn />
                            </div>
                            <div className="table-wrapper table-responsive">
                                <table className={`table table-bordered table-striped text-secondary `}>
                                    <thead className="">
                                        <tr>
                                            {tableConfigTitles &&
                                                tableConfigTitles.map((title, k) => <th key={k}>{title}</th>)}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, k1) => (
                                            <tr key={k1}>
                                                {tableConfigNames.map((name, k2) => (
                                                    <td key={k2}>{row[name] || (row.details && row.details[name])}</td>
                                                ))}
                                                <td>
                                                    <a
                                                        row-id={row.id}
                                                        className="text-primary mr-2"
                                                        title="Edit"
                                                        data-toggle="tooltip"
                                                        href="#!"
                                                        onClick={e => this.onEditClick(row.id)}
                                                    >
                                                        <i className="material-icons">edit</i>
                                                    </a>
                                                    <a
                                                        row-id={row.id}
                                                        className="text-danger"
                                                        title="Delete"
                                                        data-toggle="tooltip"
                                                        href="#!"
                                                        onClick={e => {
                                                            this.onDeleteClick(row.id);
                                                        }}
                                                    >
                                                        <i className="material-icons">delete</i>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                {/*Edit Form */}
                {showEditView && (
                    <CRUDUpdate
                        data={RecordData}
                        elements={tableConfig}
                        onSave={this.onUpdateRow}
                        onCancel={e => this.setState({ showEditView: false, RecordData: null })}
                    />
                )}
            </div>
        );
    }
}
