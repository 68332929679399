import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUserId, isSMDevice, getSubRole } from "utils";
import {
    A4P_VENDOR_ID,
    APP_IS_PETS_ENABLED,
    APP_SUPPORT_PHONE,
    APP_NAME,
    A4P_VENDOR_TYPE,
} from "data/config/variables";

class AppSidebar extends Component {
    constructor(props) {
        super(props);

        // user side menu
        this.userMenu = [
            /* { path: "/app", icon: "dashboard", title: "Dashboard" }, */
            {
                path: "/app/my-health",
                icon: "local_hospital",
                title: "Health Assessment",
            },
            {
                path: "/app/my-appointments",
                icon: "date_range",
                title: "eConsults",
            },
            {
                path: "/app/my-smo",
                icon: "alt_route",
                title: "SMO",
            },
            {
                path: "/app/my-questions",
                icon: "question_answer",
                title: "Questions",
            },
            {
                path: "/app/medical-records",
                icon: "medication",
                title: "Medical Records",
            },
        ];

        // vendor side menu
        this.vendorMenu = [
            /* { path: "/app", icon: "dashboard", title: "Dashboard" }, */
            {
                path: "/app/appointments",
                icon: "date_range",
                title: "My Appointments",
            },
            { path: "/app/listings", icon: "dns", title: "My Listings" },
            { path: "/app/settings", icon: "settings", title: "Settings" },
        ];
        
        if (getSubRole() === A4P_VENDOR_TYPE.ROOT_ADMIN) {
            this.vendorMenu.push({
                path: "/app/reports",
                icon: "bar_chart",
                title: "Reports",
            });
        }

        // add my pets for pets website
        /* if (APP_IS_PETS_ENABLED) {
            this.userMenu.push({
                path: "/app/my-pets",
                icon: "pets",
                title: "Manage Pets",
            });
        } */

        // my profile
        this.userMenu.push({
            path: "/app/my-profile",
            icon: "manage_accounts",
            title: "Settings",
        });

        // add phr for a4p vendor
        if (getUserId() === A4P_VENDOR_ID) {
            this.vendorMenu.push({
                path: "/app/users",
                icon: "account_box",
                title: "Users",
            });
        }

        if (isSMDevice()) {
            const logout = {
                path: "/app/logout",
                icon: "not_interested",
                title: "Logout",
            };
            this.vendorMenu.push(logout);
            this.userMenu.push(logout);
        }
    }

    render() {
        const { sideMenu, onSideMenuTogl } = this.props;
        let pt = this.props.location.pathname;
        let menu =
            this.props.auth.user.role === "vendor"
                ? this.vendorMenu
                : this.userMenu;

        return (
            <div
                className="be-left-sidebar pt-2"
                id="sidemenu"
                style={{ display: sideMenu ? "block" : "none" }}
            >
                <div className="left-sidebar-content">
                    <ul className="sidebar-elements p-0 m-0">
                        {menu.map((item, i) => (
                            <li key={i}>
                                <Link
                                    to={item.path}
                                    onClick={onSideMenuTogl}
                                    className={
                                        pt === item.path ||
                                        (i && pt.includes(item.path))
                                            ? "active"
                                            : "none"
                                    }
                                >
                                    <i className="material-icons">
                                        {item.icon}
                                    </i>
                                    {item.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(AppSidebar);
