import React, { Component } from "react";
import PHRIndex from "pages/app/components/phr";
import * as qs from "query-string";

export default class PHRView extends Component {
	render() {
        const petId = qs.parse(this.props.location.search).pid;
        const uId = qs.parse(this.props.location.search).uid;
        console.log(petId)
		return (
			<PHRIndex petId={petId} userId={uId} />
		);
	}
}
