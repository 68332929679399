import React, { Component } from "react";

export default class vetHouseCall extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    this.props.history.push("/vet-house-call/order");
  }

  render() {
    return (
      <main>
        <div className="position-relative mt-5 pageTitle">
          <img
            className="img-fluid"
            src="/img/vetBanner.jpg"
            alt="inner page bg"
          />
          <div className="position-absolute w-100 text-center text-white page-tagline">
            <h1 className="w-70 m-auto pt-5 pb-5">
              Vet House Call – Personalized, quality, in-home pet care service
            </h1>
          </div>
        </div>
        <div className="container py-5">
          <h3 className="text-center">It’s Really Simple</h3>
          <div className="row my-4">
            <div className="col-sm-6 offset-sm-3 col-md-4 offset-md-4">
              <div className="p-3 border-left border-primary listBx fs-1">
                Choose a Vet Service
              </div>
              <div className="p-3 border-left border-primary listBx fs-1 my-3">
                Tell Us about your Pet
              </div>
              <div className="p-3 border-left border-primary listBx fs-1">
                Book the expert Vet
              </div>
              <div className="text-center">
                <a
                  href="#!"
                  onClick={this.onClick}
                  className="btn btn-primary btn-lg full-rounded mt-5 text-uppercase px-5"
                >
                  Buy Package
                </a>
              </div>
            </div>
            <div className="clearfix" />
            <h5 className="text-center mt-5 mb-3 w-100">
              Dedicated to making veterinary care easy to accessible to all
            </h5>
            <h3 className="text-center mb-4 mt-2 w-100">
              Our In-Home Vet Care Package.
            </h3>
            <div className="col-md-6 offset-md-3 table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Pet Health Services</th>
                    <th scope="col">Activ4Pets Membership</th>
                    <th scope="col">Traditional Vet</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>2 Vet visits per year</td>
                    <td className="text-center text-success">
                      <i className="fa fa-check" />
                    </td>
                    <td>$250</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Essential Vaccines*</td>
                    <td className="text-center text-success">
                      <i className="fa fa-check" />
                    </td>
                    <td>$100</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Essential Diagnostics**</td>
                    <td className="text-center text-success">
                      <i className="fa fa-check" />
                    </td>
                    <td>$50</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Flea Tick &amp; Parasite Meds</td>
                    <td className="text-center text-success">
                      <i className="fa fa-check" />
                    </td>
                    <td>$200</td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>1 eConsultation</td>
                    <td className="text-center text-success">
                      <i className="fa fa-check" />
                    </td>
                    <td>$50</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>Unlimited “Ask a Vet”</td>
                    <td className="text-center text-success">
                      <i className="fa fa-check" />
                    </td>
                    <td style={{ color: "red" }}> X </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Total Cost</td>
                    <td className="text-center">$360</td>
                    <td>$650</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h6 className="mt-4 text-primary w-100 text-center">
            Our Pet Health Membership gives you all of the peace of mind at less
            than $1 a day. 100% Risk free. 
          </h6>
        </div>
      </main>
    );
  }
}
