import React, { Component } from "react";
import Button from "pages/components/form/Button";
import {
	A4P_SERVICE_ECONSULTATION,
	A4P_CONSULTATION_NAMES,
	A4P_APPOINTMENT_STATUSES,
	A4P_ROLE_VENDOR,
} from "data/config/variables";
import moment from "moment-timezone";
import VideoChatIndex from "pages/components/VideoChat";
import PHRIndex from "../phr";
import SectionHead from "../sectionHead";
import BookAppointment, {
	getBookingLink,
} from "pages/components/BookAppointment";
import {
	getVendorId,
	getSubDomain,
	getUserRole,
	getConsultationName,
} from "utils";
import { RenderSMOStatus } from ".";
import { confirmAlert } from "react-confirm-alert";
import { cancelAppointment } from "./network";
import config from "data/config";

export default class SMODetails extends Component {
	constructor(props) {
		super(props);
		this.menuItems = {
			details: 0,
			video: 1,
			phr: 2,
		};

		this.state = {
			selMenu: this.menuItems.details,
			subDomain: getSubDomain(),
		};
	}

	render() {
		const { menuItems } = this;
		const { selMenu, subDomain } = this.state;
		const { smo, onGoBack, isNew, timezone } = this.props;
		const { details, status, created_at } = smo || {};
		const { pet, petName, petType, petBreed, query, files, firstOpinion } =
			details || {};
		const slot = smo ? moment.tz(created_at, timezone) : null;
		const vendor_id = subDomain ? subDomain.vendor_id : getVendorId();
		const isVendor = getUserRole() === A4P_ROLE_VENDOR;

		details.user = { first_name: "John", last_name: "doe" };

		let menu = [],
			consultationLink = null,
			listingDetails = [];

		if (details) {
			menu = [{ id: menuItems.details, title: "Details", icon: "info" }];

			menu.push({
				id: menuItems.phr,
				title: "Medical Records",
				icon: "storage",
			});

			listingDetails = [
				{
					title: "Customer Name",
					value: `${details.user.first_name} ${details.user.last_name}`,
				},
				{ title: "Contact Number", value: details.user.phone },
				{ title: "Pet Name", value: petName },
				{ title: "Pet Type", value: petType },
				{ title: "Pet Breed", value: petBreed },
				{ title: "Query", value: query },
				{ title: "First Opinion", value: firstOpinion },
				{ title: "Supporting Documents", value: files, type: "filesArray" },

				{ title: "SMO ID", value: `SMO-${smo.id}` },
				{
					title: "Booking Date",
					value: moment
						.tz(created_at, timezone)
						.format("Do dddd, MMMM hh:mm A"),
				},
				//{ title: "User Timezone", value: details.userTZ },
				//{ title: "Listing Timezone", value: details.userTZ },
				{ title: "Status", value: RenderSMOStatus(slot, timezone, status) },
			];
		}

		return (
			<div className="mainc container-fluid">
				<SectionHead
					title={`${isNew ? "New SMO" : "SMO Details"}`}
					onGoBack={(e) => onGoBack(false)}
				/>
				{isNew ? (
					<BookAppointment vendor_id={vendor_id} />
				) : (
					<div>
						{/* Menu */}
						<div className="p-2 border bg-light">
							<ul className="nav nav-pills ">
								{menu.map((item, i) => (
									<li key={i} className="nav-item">
										<a
											href="#!"
											className={`nav-link px-5 ${
												selMenu === item.id ? "active" : ""
											}`}
											onClick={(e) => {
												e.preventDefault();
												this.setState({
													selMenu: item.id,
												});
											}}
										>
											<i
												className={`material-icons ${
													selMenu === item.id ? "text-white" : "text-primary"
												}`}
											>
												{item.icon}
											</i>
											{item.title}
										</a>
									</li>
								))}
							</ul>
						</div>

						{/* Details */}
						<div className="border p-2 mt-2 ">
							<div className="d-flex px-3 flex-column">
								<div
									className="pb-4 "
									style={{
										display: selMenu === menuItems.details ? "block" : "none",
									}}
								>
									{listingDetails.map((d, k) => {
										return (
											d.value && (
												<div key={k} className="mt-4 d-flex align-items-center">
													<div className="w-25 text-muted">{d.title}:</div>
													<div className="lead w-75 text-muted">
														{d.type === "link" ? (
															<a href={d.value} target="_blank">
																{d.value}
															</a>
														) : d.type === "filesArray" ? (
															d.value.map((file, k) => (
																<a className="d-block" href={file} key={k} target="_blank">
																	{file.split("/").pop()}
																</a>
															))
														) : (
															d.value
														)}
													</div>
												</div>
											)
										);
									})}
									{status !== A4P_APPOINTMENT_STATUSES.CANCELED && (
										<div className="mt-5">
											<Button
												btnCls="btn-primary px-4"
												iconCls="fa-user-plus"
												value="Assign SMO"
												onClick={(e) =>
													confirmAlert({
														message: `Are you sure you want to assign the SMO?`,
														buttons: [
															{
																label: "Yes",
																onClick: () => {},
															},
															{
																label: "No",
															},
														],
													})
												}
											/>
										</div>
									)}
								</div>

								<div
									style={{
										display: selMenu === menuItems.video ? "block" : "none",
									}}
								>
									<iframe
										allow="geolocation; microphone; camera"
										src={getBookingLink({
											userId: details.user.id,
											bookingId: smo.id,
											isVendor: getUserRole() === A4P_ROLE_VENDOR,
										})}
										scrolling="auto"
										frameBorder="0"
										className="FullHeight w-100"
										style={{ height: "94vh" }}
									/>
								</div>

								<div
									style={{
										display: selMenu === menuItems.phr ? "block" : "none",
									}}
								>
									<PHRIndex
										userId={details.user.id}
										petId={pet ? pet.id : null}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}
