import React, { Component } from 'react'

export default class shelterPartnership extends Component {
    render() {
        return (
            <main> 
  <div className="position-relative my-5 pageTitle">
    <img className="img-fluid" src="/img/vetBanner.jpg" alt="inner page bg" />
    <div className="position-absolute w-100 text-center text-white page-tagline">
      <h1 className="w-70 m-auto pt-5 pb-5">Shelter Program</h1></div></div><div className="container py-5">	
    <div className="row">
      <div className="col-md-8">
        <div>
          <h5>Let’s Save More Animal Lives, Together</h5>
          <p>Activ4Pets is partnering with shelters around the world to provide adopters with modern health solutions to improve the lives of their pets. We’d love to hear from you – please use the contact form to ask any questions, and we’ll get right back to you.</p>
        </div>
        <div className="my-5">
          <h5>What is Activ4Pets</h5>
          <p>Activ4Pets is a mobile health tool that empowers pet adopters, enables shelters to boost revenue, and improves staff efficiency.</p>
          <p>In this day and age, where everything is accessible at the touch of a button, doesn’t it make sense for adopters to have a tool that provides pet health records and other key information instantly?</p>
          <p>As an organization committed to animal welfare, Activ4Pets allows adopters to become more immersed and engaged in their new pet’s wellbeing. With Activ4Pets they can manage every aspect of their pet’s health at any time via smartphone, computer or tablet.</p>
        </div>
        <div className="my-5">
          <h5>How the Activ4Pets partnership program works:</h5>
          <p>After a pet is adopted, to save shelter staff time in retrieving medical paperwork, Activ4Pets provides adopters with a mobile app that contains all the necessary data about their new pet. Each Activ4Pets account is pre-loaded with information (pet age, breed, adoption date, allergies, vaccinations, medications, microchip #, etc) giving the adopter access whenever required.</p>
          <p>The health information is automatically transferred from your shelter management system to our servers, meaning staff no longer have to field calls or emails to provide said documentation.</p>
          <p>Health records are just the beginning of the client benefits. Watch this short video to discover more about our innovative mobile health solution:</p>
        </div>
        <div className="my-5">
          <h5>Built-In Marketing Tools:</h5>
          <p>Activ4Pets makes communicating with your adopters simple. By using innovative push notifications you can quickly and efficiently raise awareness about fundraising, events, and services to help drive adopters back to your shelter.</p>
          <p>What our first shelter partner says about our program:</p>
          <p>“When I learned about Activ4Pets I knew their technology would be a game changer. Activ4Pets has helped our staff save countless hours of administration and enabled our shelter to generate significant revenue. Our adopters love the smartphone app and we are thrilled they have this modern, engaging tool to help care for their new pets.”</p>
          <p><strong>Christopher Agostino –</strong><i>President &amp; CEO, Humane Society of Broward County</i></p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="p-4 border-L">
          <form>
            <div className="form-group">
              <label htmlFor="name">Your Name (required)</label>
              <input type="name" className="form-control" id="name" aria-describedby="name" placeholder />
            </div>
            <div className="form-group">
              <label htmlFor="companyName">Company (required)</label>
              <input type="name" className="form-control" id="companyName" placeholder />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Your Email (required)</label>
              <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder />
            </div>
            <div className="form-group">
              <label htmlFor="number">Number</label>
              <input type="tel" className="form-control" id="number" placeholder aria-invalid="false" autoComplete="off" />
            </div>
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Comments</label>
              <textarea className="form-control" id="exampleFormControlTextarea1" rows={10} defaultValue={""} />
            </div>
            <button type="submit" className="btn btn-primary">Send</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>

        )
    }
}
