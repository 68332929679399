import React, { Component } from "react";
import { getUser } from "utils";
import moment from "moment-timezone";
import SectionHead from "pages/app/components/sectionHead";

export default class ReportsIndex extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, confId: this.props.id };
    }
    render() {
        const { loading } = this.state;

        const user = getUser();
        const vid = user.vendor_id;
        const ts = user.vendor
            ? moment
                  .tz(user.vendor.created_at, moment.tz.guess())
                  .format("YYYY-MM-DD hh:mm")
            : "1980-01-01";

        return (
            <div className="mainc container-fluid">
                <SectionHead title="Appointments Report" />
                <div>
                    {loading && <p className="mt-3 col-12">loading....</p>}
                    <iframe
                        src={`https://bi.activ4pets.com/public/dashboards/w6Zgz5xzUqzPvXjoLOeEWvHj6LCprl50VM5L50GK?org_slug=default&p_app_status=1&p_con_type=1&p_crt_at=${ts}&p_range=d_last_30_days&p_vendor_id=${vid}`}
                        //src={`https://bi.activ4pets.com/public/dashboards/w6Zgz5xzUqzPvXjoLOeEWvHj6LCprl50VM5L50GK?org_slug=default&p_app_status=1&p_con_type=1&p_crt_at=2020-04-22%2009%3A47&p_range=d_last_30_days&p_vendor_id=43`}
                        scrolling="auto"
                        frameBorder="0"
                        onLoad={() => this.setState({ loading: false })}
                        className="w-100"
                        style={{ height: "80vh" }}
                    />
                </div>
            </div>
        );
    }
}
