export const calculateDiseaseRisk = (form) => {
	const getSum = (category) => {
		let sum = 0;
		for (let key in form) {
			if (key.includes(category)) {
				sum += parseInt(form[key]);
			}
		}
		return sum;
	};

	// todo disease ids to be fetched from constants
	return {
		[IHMDiseaseIDs.INTERNAL]: getSum("g-id"),
		[IHMDiseaseIDs.GASTRO_INTESTINAL]: getSum("g-gi"),
		[IHMDiseaseIDs.CARDIOVASCULAR]: getSum("g-cv"),
		[IHMDiseaseIDs.ORTHOPEDIC]: getSum("g-os"),
		[IHMDiseaseIDs.DERMATOLOGY]: getSum("g-de"),
	};
};

export const labPrescriptionGenerator = (form) => {
	let tests = {};
	const trackers = calculateDiseaseRisk(form);

	// Internal Medicine
	if (getPercent(trackers[IHMDiseaseIDs.INTERNAL]) >= IHMScores.GREEN)
		tests[IHMDiseaseIDs.INTERNAL] = [
			"CBC/CHEM/SDMA/HWT/Total-T4/UA/FELV and Fiv test",
			"Additional diagnostics: Urine culture",
		];

	// Cardiovascular
	if (getPercent(trackers[IHMDiseaseIDs.CARDIOVASCULAR]) >= IHMScores.GREEN)
		tests[IHMDiseaseIDs.CARDIOVASCULAR] = [
			"CBC/CHEM/HWT/proBNP/UA",
			"Additional diagnostics: X-ray/ echocardiogram/ultrasound/ EKG/ blood pressure reading",
		];

	// GastroIntestinal
	if (getPercent(trackers[IHMDiseaseIDs.GASTRO_INTESTINAL]) >= IHMScores.GREEN)
		tests[IHMDiseaseIDs.GASTRO_INTESTINAL] = [
			"CBC/CHEM/Fecal",
			"Additional diagnostics: UA/diarrhea realPCR panel/ fecal culture/ (canine pancreatic lipase) or fPL (feline pancreatic lipase)/ X-ray/Ultrasound/ endoscopy/ colonoscopy",
		];

	// Orthopedic
	if (getPercent(trackers[IHMDiseaseIDs.ORTHOPEDIC]) >= IHMScores.GREEN)
		tests[IHMDiseaseIDs.ORTHOPEDIC] = [
			"CBC/CHEM/x-ray",
			"Additional diagnostics: arthroscopy, MRI",
		];

	// Dermatology
	if (getPercent(trackers[IHMDiseaseIDs.DERMATOLOGY]) >= IHMScores.GREEN)
		tests[IHMDiseaseIDs.DERMATOLOGY] = [
			"CBC/CHEM/Total-T4",
			"Additional diagnostics: Skin scrape, skin cytology, ear cytology, skin biopsy, Allergen panel",
		];

	//console.log(trackers, tests);
	return tests;
};

const getPercent = (score) => Math.round((score / IHMScores.TOTAL) * 100);

export const IHMDiseaseIDs = {
	INTERNAL: "internal",
	GASTRO_INTESTINAL: "gastroIntestinal",
	CARDIOVASCULAR: "cardiovascular",
	ORTHOPEDIC: "orthopedic",
	DERMATOLOGY: "dermatology",
};

export const IHMScores = {
	MILD: 1,
	MODERATE: 8,
	SEVERE: 10,
	TOTAL: 40,
	GREEN: 20, // upto 20 i,e 0-20 = green /Mild
	BLUE: 48,
	RED: 100,
};

// todo replace hardcoded id with stringliterals
export const IHMDiseases = [
	{
		id: IHMDiseaseIDs.INTERNAL,
		title: "Internal Disease",
		description:
			"Internal Disease deals with the complex interactions of all of your pet’s organs and bodily systems and how to treat the underlying causes of disease.",
	},
	{
		id: IHMDiseaseIDs.CARDIOVASCULAR,
		title: "Cardiovascular Disease",
		description:
			"Cardiovascular Disease is a very common health concern in dogs and cats. Clinical signs can be vague and under estimated. Veterinary Radiology is commonly used to determine underlying Heart Disease.",
	},
	{
		id: IHMDiseaseIDs.GASTRO_INTESTINAL,
		title: "Gastro-Intestinal Disorder",
		description:
			"Gastroenteritis refers to inflammation of the gastrointestinal tract, meaning the stomach and the intestines. It can be caused by infection with bacteria, viruses, parasites, medications, or even new foods. The condition often causes abdominal pain, diarrhea, vomitin­­­g, and/or other clinical signs.",
	},
	{
		id: IHMDiseaseIDs.ORTHOPEDIC,
		title: "Orthopedic Disorder",
		description:
			"Orthopedic problems, meaning issues that affect the bones, joints, or muscles, can range from a minor inconvenience to debilitating and can strike any animal. Orthopedic conditions that are frequently seen in canines include cruciate ligament tears, dysplasia of the hips or elbow, and arthritis.",
	},
	{
		id: IHMDiseaseIDs.DERMATOLOGY,
		title: "Dermatology Condition",
		description:
			"Pets suffer from many skin conditions. Allergy is a very common problem as well as bacterial skin infections and skin mites. There are many simple veterinary diagnostic tests that can identify the cause of most skin disease.",
	},
];

export const InternalDiseaseSymptoms = [
	//{ id: "g-id-et", title: "Excessive Thirst" },
	//{ id: "g-id-urination", title: "Excessive Urination" },
	//{ id: "g-id-wl", title: "Weight Loss" },
	//{ id: "g-id-pa", title: "Poor Appetite" },
	//{ id: "g-id-obesity", title: "Obesity" },
	//{ id: "g-id-lethargy", title: "Sluggishness/Lethargy" },
	//{ id: "g-id-vomit", title: "Repeated bouts of Vomiting" },
	//{ id: "g-id-glu", title: "Elevated glucose, kidney or liver enzymes" },
	{ id: "g-id-etu", title: "Excessive Thirst or Urination" },
	{ id: "g-id-wlpa", title: "Weight loss or poor appetite" },
	{ id: "g-id-obesity", title: "Obesity" },
	{ id: "g-id-lethargy", title: "Sluggishness or Lethargy" },
];

export const GastroIntestinalSymptoms = [
	{ id: "g-gi-dia", title: "Repeated bouts of Diarrhea?" },
	{ id: "g-gi-vomiting", title: "Repeated bouts of Vomiting?" },
	{ id: "g-gi-stool", title: "Blood in Stool?" },
	{ id: "g-gi-ip", title: "History of Intestinal Parasites?" },
];

export const CardiovasularSymptoms = [
	{ id: "g-cv-cough", title: "Repeated bouts of Coughing?" },
	{ id: "g-cv-sneez", title: "Repeated bouts of Sneezing?" },
	{ id: "g-cv-resp", title: "Rapid Respiration while at rest?" },
	{ id: "g-cv-mb", title: "Open Mouth Breathing (Labored)?" },
	//{ id: "g-cv-hp", title: "Any previous Heartworm Positive Test?" },
];

export const OrthopedicSymptoms = [
	{ id: "g-os-standing", title: "Difficulty Standing or Getting Up?" },
	{ id: "g-os-sj", title: "Stiff Joints or Limping?" },
	{ id: "g-os-pain", title: "Painful Noises?" },
	{ id: "g-os-noises", title: "Painful Noises when touched?" },
];

export const DermatologySymptoms = [
	{
		id: "g-de-itch",
		title: "Itching, scratching, or biting at the skin?",
	},
	{ id: "g-de-red", title: "Red and/or crusted skin lesions?" },
	{ id: "g-de-hl", title: "Hair loss?" },
	{ id: "g-de-sk", title: "Skin odor?" },
];
