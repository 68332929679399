import React, { Component } from "react";
import Auth, { AuthModes } from "../components/auth";
import { getSubDomain } from "utils";
import * as qs from "query-string";

export default class login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: this.props.location.redirectUrl || "/app",
		};
	}

	render() {
		let authMode = this.props.location.pathname.includes("/login")
			? AuthModes.login
			: AuthModes.signup;
		const subDomain = getSubDomain();

		if (this.props.location.search.includes("forgot="))
			authMode = AuthModes.changePW;

		const coupon = qs.parse(this.props.location.search).cc;

		return (
			<div className="whiteHeaderOffset mb-5">
				<div className="container">					
					<div className="row col-sm-11 col-md-5 mx-auto p-4 shadow-lg rounded">
						<Auth
							mode={authMode}
							showCoupon={subDomain ? false : true}
							redirectUrl={this.state.redirectUrl}
						/>
					</div>
				</div>
			</div>
		);
	}
}
