import {
	A4P_PAYMENT_TYPES,
	IHM_SERVICE_FEES,
	IHM_STATUS,
} from "data/config/variables";
import { processPayment } from "pages/components/BookAppointment/network";
import PayPal from "pages/components/PayPal";
import { IHMDiseases, IHMScores } from "pages/ihm/wizard/ihm";
//import RazorPayCheckout from "pages/components/RazorPay";
import { updateIHMPaymentStatus } from "pages/ihm/wizard/network";
import React, { useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Button } from "reactstrap";
import { getUser, getUserId } from "utils";

export default function IHMReportBasic(props) {
	const [payNow, setPayNow] = useState(false);
	//console.log(props.report.trackers);
	return (
		<div className="px-md-3">
			{payNow ? <PayR /> : <BasicReport {...props} setPayNow={setPayNow} />}
		</div>
	);
}

const BasicReport = (props) => (
	<div>
		<p className="lead text-center mb-5">
			Please see below the risk profile based on the inputs provided by you
		</p>
		<hr className="mb-4" />

		{IHMDiseases.map((track, key) => (
			<RenderBar
				key={key}
				track={track}
				score={props.report.trackers[track.id]}
			/>
		))}

		<div
			className="my-5  pb-3"
			style={{
				fontSize: "1.2em",
				lineHeight: "2em",
			}}
		>
			<p>
				We recommend you purchase an order for lab tests to rule out any further
				complication. Along with prescription you get a telephonic/video
				consultation with our experienced vet.
			</p>
			<div className="my-5 text-center">
				<Button
					className="px-4"
					color="primary"
					onClick={(e) => props.setPayNow(true)}
				>
					Buy Now
				</Button>
			</div>
		</div>
	</div>
);

const PayR = (props) => {
	const user = getUser();
	return (
		<div className="text-center mx-auto mt-4 w-50">
			<h3 className="mb-3">Talk to a Doctor</h3>
			<hr className="my-4" />
			<p className="text-muted">
				Get a more accurate analysis based on lab tests and interaction with a
				specialized doctor for advise on future course of actions
			</p>

			<h1 className="mt-4 ml-3">${IHM_SERVICE_FEES}</h1>

			<div className="mt-3">
				<ul
					style={{
						listStyle: "none",
						lineHeight: "3em",
						color: "darkgray",
					}}
				>
					<li>
						<i className="fa fa-check mr-2" />
						Doctor Consultation
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						Telephonic or Video Consultation
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						E-Prescription
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						Lab Test Prescription (Optional)
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						Access to Medical Health Records
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						Advise on Medications, Diet and Exercise
					</li>
				</ul>
			</div>

			<div className="my-5 d-flex justify-content-center">
				<PayPal
					amount={IHM_SERVICE_FEES}
					onComplete={(paymentInfo) => {
						console.log(paymentInfo);
						processPayment({
							userId: getUserId(),
							paymentType: A4P_PAYMENT_TYPES.IHM,
							IHMStatus: IHM_STATUS.PAID,
							price: IHM_SERVICE_FEES,
							paymentInfo,
						})
							.then((r) => {
								console.log(r);
								window.location = "/app";
							})
							.catch((err) => console.log(err));
					}}
				/>
				{/* <RazorPayCheckout 
					amount={IHM_SERVICE_FEES * 100}
					actionTxt="Confirm and Pay"
					brandName={"ActivDr Online India"}
					brandLogo={"/img/ado-logo-sm.png"}
					name={`${user.first_name} ${user.last_name}`}
					email={user.email}
					phone={user.phone}
					onComplete={(r) => {
						updateIHMPaymentStatus(
							r.razorpay_payment_id,
							IHM_STATUS.PAID
						)
							.then((r) => (window.location = "/app"))
							.catch((err) => console.log(err));
					}}
				/> */}
			</div>
		</div>
	);
};

const RenderBar = ({ track, score }) => {
	const val = Math.round((score / IHMScores.TOTAL) * 100);
	const color =
		val > IHMScores.BLUE
			? "red"
			: val > IHMScores.GREEN
			? "royalblue"
			: "forestgreen";

	return (
		<div className="my-5 d-flex border-bottom pb-3">
			<div className="h5 w-25">{track.title}</div>
			<div className="w-50 text-muted">{track.description}</div>
			<div className="w-25">
				<div className="m-auto" style={{ width: 100, height: 100 }}>
					<CircularProgressbar
						value={val}
						text={val ? `${val}%` : "NA"}
						styles={buildStyles({
							pathColor: color,
						})}
					/>
				</div>
			</div>
		</div>
	);
};
