import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Pricing extends Component {
	render() {
		return (
			<main>
				<div className="position-relative my-5 pageTitle">
					<img
						className="img-fluid"
						src="/img/vetBanner.jpg"
						alt="inner page bg"
					/>
					<div className="position-absolute w-100 text-center text-white page-tagline">
						<h1 className="w-70 m-auto pt-5 pb-5">Pricing</h1>
					</div>
				</div>
				<section>
					<div className="container py-5 mb-5">
						<div className="row text-center align-items-end mb-5">
							<div className="col-lg-4">
								<div className="bg-white p-5 rounded-lg shadow">
									<h1 className="h6 text-uppercase font-weight-bold mb-4">
										Pay As You Go
									</h1>
									<h2 className="h1 font-weight-bold">
										$0
										<span className="text-small font-weight-normal ml-2">
											/ year
										</span>
									</h2>
									<div className="custom-separator my-4 mx-auto bg-primary" />
									<ul className="list-unstyled my-5 text-small text-left font-weight-normal">
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											Medical Records at $25/pet
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											Health Assessment at $75/pet
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											e-Consult at $30/session
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											Second Medical Opinion at $90/pet
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											Free Chat with Vet
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											Mobile App
										</li>
									</ul>
									<Link
										to="/signup"
										className="btn btn-primary btn-block p-2 shadow rounded-pill"
									>
										Sing Up
									</Link>
								</div>
							</div>
							{/* Pricing Table*/}
							<div className="col-lg-4 mb-5 mb-lg-0">
								<div className="bg-white p-5 rounded-lg shadow">
									<h1 className="h6 text-uppercase font-weight-bold mb-4">
										Gold Membership
									</h1>
									<h2 className="h1 font-weight-bold">
										$144
										<span className="text-small font-weight-normal ml-2">
											/ year
										</span>
									</h2>
									<div className="custom-separator my-4 mx-auto bg-primary" />
									<ul className="list-unstyled my-5 text-small text-left">
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											Medical Recorts up to 2 Pets
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />1 Health
											Assessment
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />1 e-Consult
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />1 Second
											Medical Opinion
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />2 Chats
											with Vet
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											Mobile App
										</li>
										{/* <li className="mb-3 text-muted">
											<i className="fa fa-times mr-2" />
											<del>MRA</del>
										</li> */}
									</ul>
									<Link
										to="/signup?cc=GOLD"
										className="btn btn-primary btn-block p-2 shadow rounded-pill"
									>
										Subscribe
									</Link>
								</div>
							</div>
							{/* END */}
							{/* Pricing Table*/}
							<div className="col-lg-4 mb-5 mb-lg-0">
								<div className="bg-white p-5 rounded-lg shadow">
									<h1 className="h6 text-uppercase font-weight-bold mb-4">
										Platinum Membership
									</h1>
									<h2 className="h1 font-weight-bold">
										$299
										<span className="text-small font-weight-normal ml-2">
											/ year
										</span>
									</h2>
									<div className="custom-separator my-4 mx-auto bg-primary" />
									<ul className="list-unstyled my-5 text-small text-left font-weight-normal">
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" /> Medical
											Records up to 4 Pets
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />2 Health
											Assessment
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />3 e-Consult
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />1 Second
											Medical Opinion
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											Unlimited Chat with Vet
										</li>
										<li className="mb-3">
											<i className="fa fa-check mr-2 text-primary" />
											Mobile App
										</li>
									</ul>
									<Link
										to="/signup?cc=PLATINUM"
										className="btn btn-primary btn-block p-2 shadow rounded-pill"
									>
										Subscribe
									</Link>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		);
	}
}
