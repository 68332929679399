import moment from "moment";
import React from "react";

const PrescriptionTemplate = React.forwardRef(
	({ petName = "Rocky", ownerName = "John Doe", prescriptions = {} }, ref) => {
		return (
			<div ref={ref}>
				<div className="py-3 px-4 my-4 mx-2" style={{ border: "1px solid" }}>
					<div className="text-center">
						<h3>Activ4Pets</h3>
						<p>
							235 SW 42nd Avenue <br /> Coral Gabes, Florida <br />
							33134
						</p>
					</div>
					<hr />

					<div className="d-flex justify-content-between">
						<div>
							<p>
								<span className="text-secondary mr-2">Pet Name:</span>{" "}
								<strong>{petName}</strong>
							</p>
							<p>
								<span className="text-secondary mr-2">Owner Name:</span>{" "}
								<strong>{ownerName}</strong>
							</p>
						</div>
						<div>
							<p>
								<span className="text-secondary mr-2">Prescription Date:</span>{" "}
								<strong>{moment().format("MM/DD/YYYY")}</strong>
							</p>
						</div>
					</div>
					<hr />

					<div className="d-flex">
						<h1 className="w-25">Rx </h1>
						<div>
							{Object.entries(prescriptions).map(([key, value]) => (
								<div className="mb-3" key={key}>
									<div className="text-secondary">{key}:</div>
									<ul className="list-unstyled">
										{value.map((p, k) => (
											<li className="mb-2" key={k}>
												<strong>{p}</strong>
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					</div>
					<hr />

					<div className="small text-secondary text-center pt-2">
						Activ4Pets: Diagnostics Prescription (www.activ4pets.com)
					</div>
				</div>
			</div>
		);
	}
);

export default PrescriptionTemplate;
