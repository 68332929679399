// ennivronment specific config variables
const env_config = {
  development: {
    ENABLE_CONSOLE_LOG: true,
    ENABLE_REDUX_CHROME_EXT: false,
    TOKBOX_EMBED_ID: '535084c0-1bb0-4604-9fda-a194f17f704c',
    PAYPAL_CLIENT_ID: 'AZ8Je6eXuHcBTxgFTxI4r32zRBrNX2cWYwDRqeW-kmeT-SgUOWTq9bGVlK4dKzmWGvQxgYs6UKC5gEyY',
    SITE_URL: process.env.REACT_APP_DEV_SITE_URL || "https://activdoctors.us",
    GQL_ENDPOINT: process.env.REACT_APP_DEV_GQL_EP
  },
  production: {
    ENABLE_CONSOLE_LOG: false,
    ENABLE_REDUX_CHROME_EXT: false,
    TOKBOX_EMBED_ID: 'e3ea05ad-b3cd-4061-afa2-dabdcc42ba6d',
    PAYPAL_CLIENT_ID: 'AWHhahQIx6rKfBjOxAH_C9QU4JBhQm71oiA8Zwkm6NGOfkvzWdWeF4ubogHAu7LD8HGW9BPAMFt6B5Qj',
    SITE_URL: "https://www.activ4pets.com",
    GQL_ENDPOINT: process.env.REACT_APP_LIVE_GQL_EP
  }
};

// common variables across environments
const config = {
  ...env_config[process.env.REACT_APP_ENV || 'development'],  // export NODE_ENV=development
  ENV: process.env.REACT_APP_ENV || 'development',
  API_ENDPOINT: '', // accessed via proxy 
  RECAPTCHA_KEY : process.env.REACT_APP_RECAPTCHA_KEY,
  GOOGLE_PLACES_KEY : process.env.REACT_APP_GOOGLE_PLACES_KEY
};

export default config;