import React, { Component } from "react";
import config from "data/config";

export default class VideoChatIndex extends Component {
    constructor(params) {
        super(params);
        this.state = {
            loading: true
        };
    }

    render() {
        const { loading } = this.state;
        const id = this.props.id || "home";
        //const url = `https://login.activ4pets.com/rtc/index.html?conf=${this.props.data.id}`;
        //const url = `https://tokbox.com/embed/embed/ot-embed.js?embedId=${config.TOKBOX_EMBED_ID}&room=${id}&iframe=true`;
        const url = "https://www.activdoctorsonline.com/videochat/?u=doctor";
        return (
            <div>
                {loading && <p className="col-12">loading....</p>}
                <iframe
                    allow="geolocation; microphone; camera"
                    src={url}
                    scrolling="auto"
                    frameBorder="0"
                    onLoad={() => this.setState({ loading: false })}
                    style={{
                        width: "100%",
                        border: "0",
                        height: "80vh"
                    }}
                />
            </div>
        );
    }
}
