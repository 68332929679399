import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logoutUser } from "utils/LoginChecker";
import { Helmet } from "react-helmet";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import "./css/external.css";
import { getUser, getSubDomain } from "utils";

import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.min.js";

class AppHeader extends Component {
  constructor(params) {
    super(params);
    const user = getUser();
    this.state = {
      userPic: user.pic || "/img/profile.svg"
    };
  }

  render() {
    const { onSideMenuTogl } = this.props; 
    const subDomain = getSubDomain();
    
    return (
      <header>
        {subDomain && (
                <Helmet>
                    <title>{subDomain.name} - TeleConsulation</title>
                </Helmet>
        )}
        <nav className="navbar navbar-expand be-top-header">
          <div className="container-fluid pl-0">
            <div className="mr-md-3">
              {subDomain ? (
                <Link className="navbar-brand p-1" to="/">
                  {subDomain.logo ? (
                    <img
                      src={subDomain.logo}
                      className=""
                      alt={subDomain.name}
                      style={{ width: "240px" }}
                    />
                  ) : (
                    <div className="h5">{subDomain.name}</div>
                  )}
                </Link>
              ) : (
                <Link className="navbar-brand p-1" to="/">
                  <img src="/img/a4pLogo.png" alt="a4pLogo" />
                </Link>
              )}
            </div>
            <button
              className="d-lg-none menu-btnSm"
              type="button"
              id="showSidemenu"
              onClick={onSideMenuTogl}
            >
              <i className="material-icons">menu</i>
            </button>
            <div className="d-none d-lg-block ml-auto">
              <ul className="nav navbar-nav navIcons">
                {/* <li className="nav-item position-relative">
                                    <a className="nav-link" href="#!">
                                        <i className="material-icons">
                                            {" "}
                                            notifications
                                        </i>
                                        <span className="badgeNoti">3</span>
                                    </a>
                                </li> */}
                <li className="nav-item ">
                  <a
                    id="myAccount"
                    href="#!"
                    className="nav-link dropdown-toggle "
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ marginTop: "-5px" }}
                  >
                    <img
                      style={{
                        width: "32px",
                        borderRadius: "50%"
                      }}
                      className="img-responsive"
                      src={this.state.userPic}
                    />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right mt-1 mr-1">
                    <Link className="dropdown-item" to="/app">
                      <i className="material-icons">dashboard</i> Dashboard
                    </Link>
                    <div className="dropdown-divider" />
                    <a
                      className="dropdown-item"
                      href="#!"
                      onClick={() => logoutUser()}
                    >
                      <i className="material-icons">power</i> Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default AppHeader;
