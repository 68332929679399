import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "pages/public/layout/css/style.css";
import "pages/public/layout/css/external.css";
import Steps from "./steps";
import Auth from "pages/components/auth";
import Payment from "./payment";
import Thankyou from "./thankyou";
import NotFound from "../404";
import { pay, checkForDiscount } from "./network";
import { A4P_ROLE_USER } from "data/config/variables";
import isEmpty from "utils/isEmpty";
import { getUserEmail } from "utils";

class CheckoutIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitBtnTxt: "Confirm and Pay",
      paymentError: false,
      checkout: this.props.checkout,
      steps: ["Login", "Payment", "Confirmation"],
      currentStep: 1 // defaults to 1 after login
    };
    this.onPaymentComplete = this.onPaymentComplete.bind(this);
  }

  componentDidMount() {
    // check if there are any discounts availanle under any plan
    const { checkout } = this.state;
    if (!isEmpty(checkout)) {
      const {
        productId,
        details: { price }
      } = checkout;

      if (!price) {
        this.setState({
          checkout: { ...checkout, newPrice: price }
        });
      } else {
        checkForDiscount(productId, price).then(r => {
          console.log(r);
          if (r.status) {
            this.setState({
              checkout: { ...checkout, newPrice: r.newPrice }
            });
          }
        });
      }
    }
  }

  onPaymentComplete(paymentInfo) {
    const data = {
      ...this.props.checkout,
      userId: this.props.auth.user.id,
      email: getUserEmail(),
      paymentInfo
    };
    this.setState({ submitBtnTxt: "Please Wait...", paymentError: false });
    pay(data)
      .then(r => {
        console.log(r);
        if (r.result === "Error")
          this.setState({
            submitBtnTxt: "Confirm and Pay",
            paymentError: r.status
          });
        else {
          this.setState({ currentStep: 3 });
          //setCheckoutData(null)
        }
      })
      .catch(err => console.log(err));
  }

  render() {
    let { checkout } = this.state;
    return (
      <div className="container">
        <div className="d-flex flex-column">
          <div className="navbar-brand p-2">
            <img
              src="/img/activ4pets_colored-logo.png"
              className="coloredLogo"
              alt="Activ4Pets"
            />
          </div>
          {isEmpty(checkout) ? (
            <NotFound />
          ) : !this.props.auth.isAuthenticated ||
            this.props.auth.user.role !== A4P_ROLE_USER ? (
            <div className="mt-5 w-40 align-self-center">
              <Auth />
            </div>
          ) : (
            <div className="align-self-center">
              <div className="d-none d-md-block my-2 text-center">
                <Steps
                  currentStep={this.state.currentStep}
                  steps={this.state.steps}
                />
              </div>
              <div className="mt-5">
                {this.state.currentStep === 1 ? (
                  <Payment
                    submitBtnTxt={this.state.submitBtnTxt}
                    paymentError={this.state.paymentError}
                    onPaymentComplete={this.onPaymentComplete}
                    info={checkout}
                  />
                ) : (
                  <Thankyou />
                )}
              </div>
            </div>
          )}

          <div className="py-4 border-top ">
            <div className="text-center">
              <small>
                <Link to="/privacy-policy/">Privacy Policy</Link> |
                <Link to="/terms-conditions/">
                  &nbsp; Terms &amp; Conditions &nbsp;
                </Link>
                | Copyright 2012 - 2019 Activ4Pets LLC | All Rights Reserved
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  checkout: state.config.Checkout
});

export default connect(mapStateToProps, null)(CheckoutIndex);
