import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { logoutUser } from "utils/LoginChecker";
import { Helmet } from "react-helmet";
import * as qs from "query-string";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import "./css/external.css";
import { getUser, getSubDomain, isSMDevice } from "utils";

class PublicHeader extends Component {
	constructor(params) {
		super(params);
		const user = getUser();
		this.state = {
			changeBackground: false,
			userPic: user.pic || "/img/profile.svg",
			subDomain: getSubDomain(),
		};
	}

	listenScrollEvent = (e) => {
		if (window.scrollY > 100) {
			this.setState({ changeBackground: true });
		} else {
			this.setState({ changeBackground: false });
		}
	};

	componentDidMount() {
		window.addEventListener("scroll", this.listenScrollEvent);
	}

	render() {
		const { subDomain } = this.state;

		let headerCls = "site-header fixed-top bg-white navbar-light ";

		headerCls += this.props.showTransparentBG
			? this.state.changeBackground
				? "whiteHeader"
				: "tranHeader"
			: " whiteHeader";

		if (subDomain) headerCls = "";

		// skip rendering menu for certain keywords
		const exclKeywords = ["login", "signup"];
		let ki, showHeaderMenu = true;
		const pt = this.props.location.pathname;
		for (ki of exclKeywords) {
			let regex = new RegExp(`${ki}.*`);
			if (Boolean(pt.match(regex))) {
				showHeaderMenu = false;
				break;
			}
		}

		const coupon = qs.parse(this.props.location.search).cc;
		if(coupon && isSMDevice())		
			document.documentElement.style.setProperty('--nav-bar-height', '100px');

		return (
			<header
				id="masthead"
				className={headerCls}
				role="banner"
				style={this.state}
			>
				{subDomain && (
					<Helmet>
						<title>{subDomain.name} - TeleConsulation</title>
					</Helmet>
				)}
				<div className="container">
					<nav className="navbar navbar-expand-md my-2 p-0">
						<div className="navbar-brand ">
							{subDomain ? (
								<Link to="/">
									{subDomain.logo ? (
										<img
											src={subDomain.logo}
											className=""
											alt={subDomain.name}
											style={{ width: "320px" }}
										/>
									) : (
										<div className="h5">
											{subDomain.name}
										</div>
									)}
								</Link>
							) : (
								<Link to="/">
									<img
										src="/img/activ4pets_white-logo.png"
										className="whiteLogo d-none"
										alt="Activ4Pets"
									/>
									<img
										src="/img/activ4pets_colored-logo.png"
										className="coloredLogo"
										alt="Activ4Pets"
									/>
								</Link>
							)}
						</div>
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#main-nav"
							aria-controls
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon" />
						</button>
						
						{/* show partner logo when coupon applied */}
						{coupon && <div className={ isSMDevice()? "" : "ml-auto" }>
							<img
								id="clientLogo"
								src=""
								style={{ height: "50px" }}
							/>
						</div>}

						{showHeaderMenu && (
							<div
								id="main-nav"
								className="collapse navbar-collapse justify-content-end"
							>
								<ul id="menu-main-menu" className="navbar-nav">
									{!subDomain && (
										<li className="nav-item dropdown">
											<a
												className="nav-link dropdown-toggle"
												href="#!"
												role="button"
												id="dropdownMenuLink"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												Services &nbsp;&nbsp;
											</a>

											<div
												className="mt-2 dropdown-menu"
												aria-labelledby="dropdownMenuLink"
											>
												{/* <Link
													className="dropdown-item"
													to="/vet-appointments/"
												>
													<i className="material-icons">
														meeting_room
													</i>{" "}
													Vet Appointments
												</Link> 
												<div className="dropdown-divider" />*/}
												<Link
													className="dropdown-item"
													to="/pet-health-assessment/"
												>
													<i className="material-icons">
														touch_app
													</i>{" "}
													Health Assessments
												</Link>{/* 
												<Link
													className="dropdown-item"
													to="/vet-house-call/"
												>
													<i className="material-icons">
														store_mall_directory
													</i>{" "}
													House Visits
												</Link> */}
												<div className="dropdown-divider" />
												<Link
													className="dropdown-item"
													to="/e-consultation/"
												>
													<i className="material-icons">
														voice_chat
													</i>{" "}
													eConsult (Video or Phone)
												</Link>
												<div className="dropdown-divider" />
												<Link
													className="dropdown-item"
													to="/talk-to-a-vet/"
												>
													<i className="material-icons">
														question_answer
													</i>{" "}
													Chat With A Vet (Free)
												</Link>
												<div className="dropdown-divider" />
												<Link
													className="dropdown-item"
													to="/second-medical-opinion/"
												>
													<i className="material-icons">
														call_split
													</i>{" "}
													Second Medical Opinion
												</Link>
												<div className="dropdown-divider" />
												<Link
													className="dropdown-item"
													to="/pet-medical-records/"
												>
													<i className="material-icons">
														pets
													</i>{" "}
													Medical Records
												</Link>
											</div>
										</li>
									)}
									{!subDomain && (
										<>
											<li
												id="menu-item-206"
												className="nav-item menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
											>
												<Link
													title="Pricing"
													to="/pricing/"
													className="nav-link"
												>
													Pricing
												</Link>
											</li>
											<li
												id="menu-item-206"
												className="nav-item menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
											>
												<Link
													title="For Vets"
													to="/for-vet/"
													className="nav-link"
												>
													For Vets
												</Link>
											</li>
										</>
									)}
									{!this.props.loggedIn ? (
										<li
											id="menu-item-29"
											className="nav-item menu-item menu-item-type-post_type menu-item-object-page menu-item-29"
										>
											<Link
												title="Login"
												to="/login/"
												className="nav-link"
											>
												Login
											</Link>
										</li>
									) : (
										<li className="nav-item ">
											<a
												className="nav-link drodown-toggle"
												href="#!"
												role="button"
												id="dropdownMenuLink"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
												style={{ marginTop: "-5px" }}
											>
												<img
													style={{
														width: "32px",
														borderRadius: "50%",
													}}
													className="img-responsive"
													src={this.state.userPic}
												/>
											</a>
											<div className="dropdown-menu dropdown-menu-right ">
												<Link
													className="dropdown-item"
													to="/app"
												>
													<i className="material-icons">
														dashboard
													</i>{" "}
													Dashboard
												</Link>
												<div className="dropdown-divider" />
												<Link
													className="dropdown-item"
													to="#"
													onClick={() => logoutUser()}
												>
													<i className="material-icons">
														power
													</i>{" "}
													Logout
												</Link>
											</div>
										</li>
									)}
									{/* to avoid backgroud on last li - inserting dummy li*/}
									{this.props.loggedIn && <li />}
								</ul>
							</div>
						)}
					</nav>
				</div>
			</header>
		);
	}
}

export default withRouter(PublicHeader);
