import React, { Component } from 'react'

export default class faqs extends Component {
    render() {
        return (
            <main> 
  <div className="position-relative my-5 pageTitle">
    <img className="img-fluid" src="/img/vetBanner.jpg" alt="inner page bg" />
    <div className="position-absolute w-100 text-center text-white page-tagline">
      <h1 className="w-70 m-auto pt-5 pb-5">FAQ’s </h1></div></div><div className="container py-5">	
    <div className="accordion mb-4" id="accordionExample">
      <div className="card">
        <div className="card-header bg-primary p-0 m-0" id="headingOne">
          <h2 className="p-0 m-0">
            <button className="btn btn-link text-white d-block p-4 w-100 text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <strong>Pet Health Records</strong>
            </button>
          </h2>
        </div>
        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample" style={{}}>
          <div className="card-body">
            <br /><br />
            <h5 className="text-primary">What information does a pet health record contain?</h5>
            A pet health record includes every piece of health information from your pet’s medical history, such as prescriptions, vaccinations, allergies, surgery reports, conditions, test results, screenings, X-Rays, MRIs, CT scans, plus insurance information, adoption information (if applicable), date of birth and much more. Please find more information about pet health records here.<br /><br />
            <h5 className="text-primary">Where do I access my pet’s health record?</h5>
            Members can access their pet’s health record from anywhere in the world using any device with an internet connection. Simply go the Activ4Pets website and click “Sign In” to enter the platform. You can also access the information using the Activ4Pets mobile app (available on iPhone and Android).<br /><br />
            <h5 className="text-primary">What is Medical Records Assistance/MRA?</h5>
            Medical Records Assistance (or MRA) is the process of retrieving all your pet’s health information from your veterinarian/s. MRA is included with Activ4Pets premium membership at no extra cost. You can find out more about MRA here.<br /><br />
            <h5 className="text-primary">Why do I need a mobile pet health record?</h5>
            A mobile pet health record is like a baby book for your pet. It stores your animal’s entire health history as well as insurance information, food plans, pet photos and much more. Many of us have paper records that often get misplaced or lost. A mobile pet record puts those records instantly at your fingertips. Put simply, it’s the most convenient way to access information about your pet when you need it most. For a more complete answer to this question, please see our article on the subject here.<br /><br />
            <h5 className="text-primary">How does a pet health record save me money?</h5>
            The ability to peruse your pet’s health history 24/7 provides a huge potential to save money. Many pet owners get duplicate or early tests for their animals, simply because they have no record or memory of the previous test. Activ4Pets empowers members to avoid such instances by providing easy access to those records, and instant answers for any medical questions your vet may ask. Equally, because you can keep track of your animal wellness and set up health reminders, Activ4Pets can help resolve minor issues before they become major and more costly ones.
            <br /><br /><br />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header bg-primary p-0 m-0" id="headingTwo">
          <h2 className="p-0 m-0">
            <button className="btn btn-link collapsed text-white d-block p-4 w-100 text-left" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <strong>e-Consultation and Peer-to-Peer Consult</strong>
            </button>
          </h2>
        </div>
        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
          <div className="card-body">
            <br /><br />
            <h5 className="text-primary">How long does an e-Consultation last?</h5>
            e-Consultations typically last between 10-15 minutes.<br /><br />
            <h5 className="text-primary">What can I expect during an e-Consultation?</h5>
            You can request an e-Consultation for non-critical guidance on pet-related issues. e-Consultations are intended to provide clarity for pet owners and as an alternative to ‘Googling’ for answers which can often be misleading. e-Consultations provide pet owners with peace of mind with a service from their trusted veterinarian.<br /><br />
            <h5 className="text-primary">Can the veterinarian prescribe medication during the e-Consultation?</h5>
            Due to new American laws, our vets currently cannot prescribe narcotic type medications, but the veterinarians can recommend over the counter medications to assist in the recovery of your pet or let you know what prescription medication may be necessary.<br /><br />
            <h5 className="text-primary">How do I schedule an e-Consultation?</h5>
            Members should login to the Activ4Pets platform, click the “e-Consultation” link and complete the three-step set-up process. Payment is made in advance and a wide selection of appointments are available. e-Consultations are conducted on our secure platform via webcam, similar to a Skype chat. You can find out more about e-Consultations here.<br /><br />
            <h5 className="text-primary">Can I conduct an e-Consultation using my Smartphone?</h5>
            Yes, you can conduct an e-consultation through the Activ4Pets App, and any smart device, or computer with a wifi or cellular connection.<br /><br />
            <h5 className="text-primary">How does my vet request a Peer-to-Peer Consult?</h5>
            Your veterinarian should login to the Activ4Pets platform, click the “Peer-to-Peer Consult” link and complete the three-step request process. Peer-to-Peer Consults are delivered to your vet via the Activ4Pets platform in PDF format within 48 hours. You can find out more about Peer-to-Peer Consults here.<br /><br />
            <h5 className="text-primary">How long does the Peer-to-Peer Consult process take?</h5>
            Providing all pet health information is up-to-date and in order, Peer-to-Peer Consults are provided within 48 hours of receiving the request. It may take up to five days if further information is required or if medical records are incomplete.<br /><br />
            <h5 className="text-primary">Are there additional fees for a Peer-to-Peer Consult or e-Consultation?</h5>
            Yes. Activ4Pets members are charged on a per-use basis for Peer-to-Peer Consults and e-Consultations. Peer-to-Peer Consults are charged at $95, while e-Consultations are charged at $25.
            <br /><br /><br />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header bg-primary p-0 m-0" id="headingThree">
          <h2 className="p-0 m-0">
            <button className="btn btn-link collapsed text-white d-block p-4 w-100 text-left" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <strong>General Questions</strong>
            </button>
          </h2>
        </div>
        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
          <div className="card-body">
            <br /><br />
            <h5 className="text-primary">How much does Activ4Pets membership cost?</h5>
            Please visit the pricing page for more information.<br /><br />
            <h5 className="text-primary">Can I try Activ4Pets for free?</h5>
            Yes. You can sign up for a free Activ4Pets account and try some of the features, including participating in our online pet-loving community, pet photo galleries, calendar/reminders, messaging and more. You can also download the free Activ4Pets app to try out the same features.<br /><br />
            <h5 className="text-primary">How can I contact Activ4Pets?</h5>
            You can send us a message or request a call back for more information on our contact page, which you can find here. If you’d prefer to call us, the toll-free number is 855-PETDATA (855-738-3282).<br /><br />
            <h5 className="text-primary">Does Activ4Pets partner with other organizations and companies?</h5>
            <strong className="text-success">Yes. Activ4Pets partners with many Fortune 1000 companies, governmental agencies, educational institution, Veterinarians, Animal Hospitals, Humane Societies, Brokers, Retailers and other applicable organizations. You can find out more about Activ4Pets partnership opportunities here.</strong>
            <br /><br /><br />
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header bg-primary p-0 m-0" id="headingfour">
          <h2 className="p-0 m-0">
            <button className="btn btn-link collapsed text-white d-block p-4 w-100 text-left" type="button" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
              <strong>Account Issues</strong>
            </button>
          </h2>
        </div>
        <div id="collapsefour" className="collapse" aria-labelledby="headingfour" data-parent="#accordionExample">
          <div className="card-body">
            <br /><br />
            <h5 className="text-primary">What if I lost my password?</h5>
            If you have lost or forgotten your password you can email yourself a reminder by using the link on the sign in page.<br /><br />
            <h5 className="text-primary">What security features does Activ4Pets offer?</h5>
            Activ4Pets is committed to protecting member privacy. We utilize military-level security procedures including Advanced Encryption Standard (AES) protocols meaning information is always completely private and secure. Please find more information about security here.
            <br /><br /><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

        )
    }
}
