import { IHM_STATUS, profile_pic_types } from "data/config/variables";
import BookAppointment from "pages/components/BookAppointment";
import MyDropZone from "pages/components/form/MyDropZone";
import { updateIHMBookingStatus } from "pages/ihm/wizard/network";
import React, { useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { getUser } from "utils";
import PrescriptionTemplate from "./prescription";

export default function IHMReportAdvanced({ report, answers }) {
	const componentRef = useRef();
	const [labFiles, saveFiles] = useState([]);
	const [bookingConfirmed, setBookingConfirmed] = useState(false);
	const user = getUser();
	//console.log(answers, report, user);

	const onComplete = (bookingId) => {
		console.log(bookingId, labFiles);
		updateIHMBookingStatus(bookingId, labFiles, IHM_STATUS.BOOKED_APPOINTMENT)
			.then((r) => setBookingConfirmed(true))
			.catch((r) => console.log(r));
	};

	return (
		<div className="px-md-3">
			{!bookingConfirmed && (
				<div>
					<h3 className="text-center mb-5">Advanced Report</h3>
					<div className="text-muted">
						<h5 className=" mb-4">Please read the instructions below:</h5>

						<p>
							Congratulations on taking care of your Pet Health. Please get the
							following lab tests completed(as per the prescription below)
							before the consultation for us to provide an accurate analysis.
						</p>

						<small>
							Note: Kindly note the lab tests are optional, you can still go
							ahead with the consultation without the lab tests reports.
						</small>
					</div>
					<hr className="my-4" />
					<h6>Prescription:</h6>
					<div className="w-75">
						<PrescriptionTemplate
							ref={componentRef}
							petName={answers["g-name"]}
							ownerName={`${user.first_name} ${user.last_name}`}
							prescriptions={report.prescriptions}
						/>
						<ReactToPrint
							trigger={() => <button>Print/Save this Prescription!</button>}
							content={() => componentRef.current}
						/>
					</div>

					<hr className="my-4" />

					<h6>1. Upload lab reports here[Optional] </h6>
					<div>
						<MyDropZone
							placeHolder="Drag and Drop files here or Click to browse"
							uploadType={profile_pic_types.phr}
							acceptTypes="*"
							height={80}
							canRemove={false}
							onUpload={(files) => saveFiles(files)}
						/>
					</div>

					<hr className="my-4" />
					<h6 className="mt-4">2. Book Appointment</h6>
				</div>
			)}
			<BookAppointment vendor_id={1} onComplete={onComplete} />
		</div>
	);
}
