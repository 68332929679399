import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import {
	Col,
	Row,
	Tooltip,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	FormFeedback,
	FormText,
} from "reactstrap";
import { BottomNav, CustomIHMSelect } from ".";
import { InternalDiseaseSymptoms } from "./ihm";

export default function Step1(props) {
	const [, forceUpdate] = useState(false);
	const validator = useRef(new SimpleReactValidator());

	const [form, saveForm] = useState({});
	const submitForm = (event) => {
		event.preventDefault();
		if (validator.current.allValid()) {
			form.step1complete = true;
			props.saveForm(form);
			props.nextStep();
		} else {
			validator.current.showMessages();
			forceUpdate(1);
		}
	};

	const onChange = (e) => {
		let val = e.target.value;
		if (e.target.type === "checkbox") {
			val = e.target.checked;
		}
		saveForm({ ...form, [e.target.name]: val });
	};

	return (
		<div className="mt-5">
			<Form onSubmit={submitForm}>
				<h5 className="mt-5">About Pet:</h5>
				<Row className="mt-3">
					<Col xs={6} md={6}>
						<FormGroup>
							<Label for="exampleEmail" data-tip="Enter the pet's name">
								Pet Name
								<i className="fa fa-info-circle px-1" />
								<span className="text-danger">*</span>
							</Label>
							<Input type="text" name="g-name" onChange={onChange} />
							{validator.current.message("name", form["g-name"], "required")}
						</FormGroup>
					</Col>
					<Col xs={6} md={6}>
						<FormGroup>
							<Label for="exampleSelect">
								Type
								<span className="text-danger">*</span>
							</Label>
							<Input type="select" name="g-type" onChange={onChange}>
								<option value="">Select Type</option>
								<option value="male">Dog</option>
								<option value="female">Cat</option>
								<option value="other">Other</option>
							</Input>
							{validator.current.message("type", form["g-type"], "required")}
						</FormGroup>
					</Col>
					<Col xs={6} md={6}>
						<FormGroup>
							<Label>
								Age
								<span className="text-danger">*</span>
							</Label>
							<Input type="text" name="g-age" onChange={onChange} />
							{validator.current.message(
								"age",
								form["g-age"],
								"required|numeric|min:10,num|max:100,num"
							)}
						</FormGroup>
					</Col>
					<Col xs={6} md={6}>
						<FormGroup>
							<Label for="exampleSelect">
								Gender
								<span className="text-danger">*</span>
							</Label>
							<Input type="select" name="g-sex" onChange={onChange}>
								<option value="">Select</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
								<option value="other">Other</option>
							</Input>
							{validator.current.message("gender", form["g-sex"], "required")}
						</FormGroup>
					</Col>
					<Col xs={6} md={6}>
						<FormGroup>
							<Label>
								Breed
								{/* <span className="text-danger">*</span> */}
							</Label>
							<Input type="text" name="g-breed" onChange={onChange} />
							{/* validator.current.message("breed", form["g-breed"], "required") */}
						</FormGroup>
					</Col>
					<Col xs={6} md={6}>
						<FormGroup>
							<Label for="exampleSelect">Species</Label>
							<Input type="select" name="g-species" onChange={onChange}>
								<option value="">Select Species</option>
								<option value="canine">Canine</option>
								<option value="feline">Feline</option>
								<option value="other">Other</option>
							</Input>
						</FormGroup>
					</Col>
					<Col xs={6} md={6}>
						<FormGroup>
							<Label for="exampleSelect">Neutering</Label>
							<Input type="select" name="g-neutering" onChange={onChange}>
								<option value="">Select</option>
								<option value="canine">Spayed</option>
								<option value="feline">Neutered</option>
								<option value="other">None</option>
							</Input>
						</FormGroup>
					</Col>
				</Row>

				<h5 className="mt-5">Internal Disease Diagnosis:</h5>
				<Label className="text-muted" for="exampleCheckbox">
					Please select the applicable severity of the symptoms displayed by
					your pet
				</Label>
				<Row className="mt-3">
					{InternalDiseaseSymptoms.map((e, k) => (
						<Col key={k} className="mb-3" xs={6} md={6}>
							{/* <CustomInput
								type="switch"
								id={e.id}
								name={e.id}
								label={e.title}
								onChange={onChange}
							/> */}
							<CustomIHMSelect
								name={e.id}
								label={e.title}
								onChange={onChange}
							/>
						</Col>
					))}
				</Row>
			</Form>

			<BottomNav previousStep={props.previousStep} nextStep={submitForm} />
		</div>
	);
}
