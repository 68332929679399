import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import ManageLiDetails from "./details";
import ManageLiConsultation from "./consultation";
import SectionHead from "pages/app/components/sectionHead";
import { createOrUpdateListing } from "../network";
import Button from "pages/components/form/Button";

export default class ManageListing extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({});

    const { item } = this.props || {};

    this.state = {
      item,
      onSaved: false
    };

    this.onUpdate = this.onUpdate.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit() {
    if (this.validator.allValid()) {
      const { item } = this.state; 
      const { id } = item;
      this.setState({ onSaved: false });
      createOrUpdateListing(item)
        .then(r => {
          if (!id) {
            const [row] = r.data.insert_vendor_listings.returning;
            item.id = row.id;
          } 
          this.setState({ onSaved: true });
          this.props.onUpdate(item);
        })
        .catch(r => console.log(r));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  onUpdate(data) {
    const v = { ...this.state.item, ...data };
    this.setState({ item: v });
  }

  render() {
    const { onSaved } = this.state;
    const { item, onGoBack } = this.props;
    const { id } = item || {};
    this.validator.purgeFields();

    const boxStyle = {
      border: "1px solid #ccc",
      padding: "1em",
      marginBottom: "0.8em"
    };
    const boxTitle = {
      borderBottom: "1px solid #eee",
      paddingBottom: "0.3em",
      marginBottom: "0.8em"
    };

    return (
      <div className="mainc container-fluid">
        <SectionHead title={id ? "Update Listing" : "New Listing"} onGoBack={onGoBack} />
        
        <div className="pt-2">
          <div style={boxStyle}>
            <h3 className="h5 text-muted" style={boxTitle}>
              Details:
            </h3>
            <ManageLiDetails
              data={item}
              validator={this.validator}
              onUpdate={this.onUpdate}
            />
          </div>

          <div style={boxStyle}>
            <h3 className="h5 text-muted" style={boxTitle}>
              Timings:
            </h3>
            <ManageLiConsultation
              data={item}
              validator={this.validator}
              onUpdate={this.onUpdate}
            />
          </div>
        </div>

        {onSaved && (
          <div className="alert alert-success">
            <strong>Saved Successfully!</strong>.
          </div>
        )}

        <div className="py-3">
          <Button
            btnCls="btn-outline-secondary mr-4"
            iconCls="fa-long-arrow-left"
            value="Go Back"
            onClick={onGoBack}
          />

          <Button
            btnCls="btn-success px-5 mr-4"
            iconCls="fa-save"
            value={`${id ? "Update" : "Save"} Listing`}
            onClick={this.onFormSubmit}
          />
        </div>
      </div>
    );
  }
}
