import React, { Component } from "react";
import MyPets from "pages/components/MyPets"; 
import SectionHead from "../components/sectionHead";

export default class UserMyPets extends Component {
    render() {
        return (
            <div>
                <div className="mainc container-fluid">
                    <div>
                        <SectionHead title="My Pets" />
                        <div className="p-2">
                            <MyPets mode="update" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
