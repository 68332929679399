import React, { Component } from "react";
import PropTypes from "prop-types";
import { getPetType, getUserId } from "utils";
import AddPet from "./AddPetForm";
import { getPets } from "./network";
import isEmpty from "utils/isEmpty";
import { Link } from "react-router-dom";

class MyPets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userId || getUserId(),
            items: [],
            data: null,
            selId: null,
            showPopup: false
        };
        this.onClick = this.onClick.bind(this);
        this.onItemUpdated = this.onItemUpdated.bind(this);
    }

    componentDidMount() {
        const { userId } = this.state;
        getPets(userId)
            .then(r => {
                if (r.data.pets.length) {
                    let activPets = r.data.pets.filter(p => p.status);
                    this.setState({
                        items: this.state.items.concat(activPets)
                    });
                    if(this.props.defaultSelected && activPets.length > 0) 
                        this.onClick(activPets[0].id)
                }
            })
            .catch(err => console.log(err));
    }

    onClick(id) {
        const pet = this.state.items.find(item => item.id === id);
        if (id && this.props.mode === "select") {
            this.setState({ showPopup: false, selId: id });
        } else {
            // manage mode ; and aslo called for add pet fro either mode
            this.setState({ selId: null, showPopup: true, data: pet });
        }
        if (this.props.onPetSelected) this.props.onPetSelected(pet);
    }

    onItemUpdated(newItem) {
        let { items } = this.state;
        const index = items.findIndex(e => e.id === newItem.id);

        if (index === -1) items.push(newItem);
        // add case
        else if (newItem.delete) items.splice(index, 1);
        // delete case
        else items[index] = newItem; // update case

        this.setState({ items });
        console.log(newItem, this.state.items);
    }

    render() {
        const { items, showPopup, data, userId } = this.state;
        return (
            <div>
                <div className="row">
                    {this.props.mode === "select" && (
                        <div className="col-12 text-muted mb-2">
                            Select a pet
                        </div>
                    )}

                    {items.map((item, i) => (
                        <div key={i} className="col-sm-6 col-md-4 mb-3">
                            <div
                                onClick={() => this.onClick(item.id)}
                                className={`d-flex shadow-sm border rounded align-items-center p-3 mb-3 mr-3 ${
                                    this.props.mode === "select" &&
                                    this.state.selId === item.id
                                        ? "bg-primary text-white"
                                        : "bg-white text-primary"
                                }`}
                                style={{
                                    cursor: "pointer",
                                    height: "80px"
                                }}
                            >
                                <div className="align-self-center mr-4">
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: "2.25em" }}
                                    >
                                        pets
                                    </i>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="lead">{item.name}</div>
                                    <div>{getPetType(item.type)}</div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {this.props.showAdd && (
                        <div className="col-sm-6 col-md-4 mb-3">
                            <div
                                onClick={() => this.onClick(null)}
                                className={`d-flex shadow-sm border border-secondary rounded p-3 mb-3 mr-3 bg-white text-secondary`}
                                style={{
                                    cursor: "pointer",
                                    height: "80px"
                                }}
                            >
                                <div className="align-self-center mr-4">
                                    <i
                                        className="material-icons"
                                        style={{ fontSize: "2.25em" }}
                                    >
                                        add
                                    </i>
                                </div>
                                <div className="align-self-center mt-n1">
                                    <div className="lead">Add Pet</div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!this.props.showAdd && isEmpty(items) && (
                        <p className="col text-muted lead">
                            You have not added any pets!.
                            <Link to="/app/my-pets" className="mx-1">
                                Click here
                            </Link>
                            to add pets.
                        </p>
                    )}
                </div>

                {/* modal */}
                <AddPet
                    showModal={showPopup}
                    userId={userId}
                    data={data}
                    onItemUpdated={this.onItemUpdated}
                    onClosed={e => this.setState({ showPopup: false })}
                />
            </div>
        );
    }
}

MyPets.defaultProps = {
    // select - for selecting a pet; update - update pet
    mode: "select",
    // whether to show Add pet button
    showAdd: true,
    // defaultSelected - select the first entry by default
    defaultSelected: false,
};

MyPets.propTypes = {
    // returns the pet id when a pet is selected
    onPetSelected: PropTypes.func,
    userId: PropTypes.number,
    showAdd: PropTypes.bool,
    defaultSelected: PropTypes.bool
};

export default MyPets;
