import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import {
	Col,
	Row,
	Tooltip,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	FormFeedback,
	FormText,
} from "reactstrap";
import { BottomNav, CustomIHMSelect } from ".";
import { DermatologySymptoms, OrthopedicSymptoms } from "./ihm";

export default function Step3(props) {
	const [, forceUpdate] = useState(false);
	const validator = useRef(new SimpleReactValidator());

	const [form, saveForm] = useState({});
	const submitForm = (event) => {
		event.preventDefault();
		if (validator.current.allValid()) {
			props.saveForm(form);
			props.submitSurvey();
		} else {
			validator.current.showMessages();
			forceUpdate();
		}
	};

	const onChange = (e) => {
		let val = e.target.value;
		if (e.target.type === "checkbox") {
			val = e.target.checked;
		}
		saveForm({ ...form, [e.target.name]: val });
	};

	return (
		<div className="mt-5">
			<h5 className="mt-5">Orthopedic Diagnosis:</h5>
			<Label className="text-muted" for="exampleCheckbox">
				Please select the applicable severity of the symptoms displayed by your
				pet
			</Label>
			<Row className="mt-3">
				{OrthopedicSymptoms.map((e, k) => (
					<Col key={k} className="mb-3" xs={6} md={6}>
						<CustomIHMSelect name={e.id} label={e.title} onChange={onChange} />
					</Col>
				))}
			</Row>

			<h5 className="mt-5">Dermatology Diagnosis:</h5>
			<Label className="text-muted" for="exampleCheckbox">
				Please select the applicable severity of the symptoms displayed by your
				pet
			</Label>
			<Row className="mt-3">
				{DermatologySymptoms.map((e, k) => (
					<Col key={k} className="mb-3" xs={6} md={6}>
						<CustomIHMSelect name={e.id} label={e.title} onChange={onChange} />
					</Col>
				))}
			</Row>

			<BottomNav
				previousStep={props.previousStep}
				nextStep={submitForm}
				nextLabel="Finish"
			/>
		</div>
	);
}
