import React, { Component } from "react";
import moment from "moment";
// Import React Table
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import SectionHead from "pages/app/components/sectionHead";
import { gqlSend } from "utils/AxiosUtil";

const requestData = (pageSize, page, sorted, filtered) => {
  /* const [sort] = sorted; console.log(sort)
    order_by: {${sort? (sort.id + ":" + (sort.desc? "desc":"asc")) : ""}} */

  let where = `{}`;
  if (filtered.length) {
    const t = filtered.map(e => `${e.id}: {_ilike: "%${e.value}%"}`);
    where = `{_and: {${t.join(", ")}}}`;
  }

  const q = {
    query: `
            {
                users(
                    limit: ${pageSize}, 
                    offset: ${page * pageSize},
                    order_by: {id: desc},
                    where: ${where}
                ) {
                    id, created_at, first_name, last_name, signup_plan, source, phone, email,
                    promocode { code }
                }
            }`
  };
  console.log(q);
  return gqlSend(q);
};

class UsersList extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      pages: null,
      loading: true
    };
    this.fetchData = this.fetchData.bind(this);
    this.onViewClicked = this.onViewClicked.bind(this);
  }

  onViewClicked(id) {
    if(this.props.onSelectRow) 
        this.props.onSelectRow(id)
  }

  myFilter = ({ filter, onChange }) => {
    return (
      <input
        onKeyPress={event => {
          if (event.keyCode === 13 || event.which === 13) {
            onChange(event.target.value);
          }
        }}
      />
    );
  };

  fetchData(state, instance) {
    console.log(state.pageSize, state.page, state.sorted, state.filtered);

    this.setState({ loading: true });

    requestData(state.pageSize, state.page, state.sorted, state.filtered)
      .then(r => {
        console.log(r);
        this.setState({
          data: r.data.users,
          pages: 72030,
          loading: false
        });
      })
      .catch(r => console.log(r));
  }

  render() {
    const { data, pages, loading } = this.state;
    return (
      <div>
        <div className="mainc container-fluid">
          <SectionHead title="Users" />
          <ReactTable
            columns={[
              {
                Header: "ID",
                accessor: "id",
                filterable: false
              },
              {
                Header: "First Name",
                accessor: "first_name",
                Filter: this.myFilter
              },
              {
                Header: "Last Name",
                accessor: "last_name",
                Filter: this.myFilter
              },
              {
                Header: "Email",
                accessor: "email",
                width: 250,
                Filter: this.myFilter
              },
              {
                Header: "Phone",
                accessor: "phone",
                filterable: false
              },
              {
                Header: "Date",
                accessor: "created_at",
                filterable: false,
                Cell: row => (<span>{moment(row.original.created_at).format("DD-MMM-YYYY")}</span>)
              },
              {
                Header: "PromoCode",
                accessor: "promocode.code",
                filterable: false,
              },
              {
                header: "",
                id: "click-me-button",
                filterable: false,
                Cell: row => (
                  <button onClick={e => this.onViewClicked(row.original.id)}> View </button>
                )
              }
            ]}
            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
            data={data}
            pages={pages} // Display the total number of pages
            loading={loading} // Display the loading overlay when we need it
            onFetchData={this.fetchData} // Request new data when things change
            filterable 
            sortable ={false}
            showPageJump={false}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

export default UsersList;
