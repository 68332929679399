import React, { Component } from "react";
import SectionHead from "pages/app/components/sectionHead";
import { searchVendorListings } from "./network";
import ListingItem from "./ListingItem";
import NoRecords from "pages/app/components/noRecords";
import VendorLiDetails from "./details";
import isEmpty from "utils/isEmpty";
import ManageListing from "./details";
import { getConsultationName, getConsultationIcon } from "utils";
import BookingListingItem from "pages/components/BookAppointment/ListingItem";

class VendorListings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
            items: [],
            error: false,
            showDetails: false,
        };
        this.onSelect = this.onSelect.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onGoBack = this.onGoBack.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {key} = this.props.location; 
        // to reload the page when clicked on the same link 
        if ( key && (prevProps.location.key !== key)) {
            this.onGoBack(true);
        }
    }

    onSelect(data) {
        let item = this.state.items.find((item) => item.id === data.id);
        this.setState({ selectedItem: item, showDetails: true });
    }

    onUpdate(item) {
        const { items } = this.state;
        console.log(item, items);
        const { id } = item;
        let updated = false;
        items.forEach((e, k) => {
            if (e.id === id) {
                items[k] = { ...item };
                updated = true;
                return true;
            }
        });
        if (!updated) {
            items.push(item);
        }
        this.setState({ items });
    }

    onGoBack() {
        this.setState({ selectedItem: null, showDetails: false });
    }

    componentDidMount() {
        const { items } = this.state;

        searchVendorListings()
            .then((r) => {
                console.log(r);
                const li = r.data.vendor_listings;
                if (!isEmpty(li))
                    this.setState({
                        items: [...items, ...li],
                    });
            })
            .catch((err) => {
                this.setState({ error: true });
                console.log(err);
            });
    }

    render() {
        const {
            items,
            selectedItem,
            error,
            endOfList,
            showDetails,
        } = this.state;

        return (
            <div>
                {showDetails ? (
                    <ManageListing
                        onGoBack={this.onGoBack}
                        item={selectedItem}
                        onUpdate={this.onUpdate}
                    />
                ) : (
                    <div className="mainc container-fluid">
                        <SectionHead
                            title="My Listings"
                            createTxt="New Listing"
                            onCreateClick={(e) =>
                                this.setState({ showDetails: true })
                            }
                        />
                        {isEmpty(items) ? (
                            <NoRecords />
                        ) : (
                            <div>
                                {error ? (
                                    <div className="container text-center py-5">
                                        Sorry something went wrong. Please try
                                        again later
                                    </div>
                                ) : (
                                    <div>
                                        <div className="row">
                                            {items &&
                                                items.map((item, k) => (
                                                    <div
                                                        key={k}
                                                        className="col-md-6 col-sm-12"
                                                    >
                                                        <BookingListingItem
                                                            item={item}
                                                            onSelect={
                                                                this.onSelect
                                                            }
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

VendorListings.defaultProps = {
    limit: 10,
};

export default VendorListings;
