import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";
import Fade from "react-reveal/Fade";
import StepWizard from "react-step-wizard";
import Nav from "./nav";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import IHMWelcome from "./welcome";
import ReactTooltip from "react-tooltip";
import { getUserId } from "utils";
import { sendIHMAnswersAndReport } from "./network";
import Auth from "pages/components/auth";
import { Redirect } from "react-router-dom";
import {
	labPrescriptionGenerator,
	calculateDiseaseRisk,
	IHMScores,
} from "./ihm";

export default function IHMRSteps() {
	const [start, setStart] = useState(false);
	const [form, saveForm] = useState({});

	const onUpdateFrom = (data) => {
		saveForm({ ...form, ...data });
	};

	const [formSubmit, SetFormSubmit] = useState(false); // for tracking questionnaire submission
	useEffect(() => onComplete(), [formSubmit]);

	const [completed, setCompleted] = useState(false);
	const onComplete = () => {
		// to ensure it doesnt run at init
		if (formSubmit) {
			// pre conditions check
			if (form.step1complete && form.step2complete) {
				if (getUserId()) uploadIHMData();
				setCompleted(true);
			} else alert("Please submit the required fields");
		}
	};

	const uploadIHMData = () => {
		const basic_report = {
			trackers: calculateDiseaseRisk(form),
			prescriptions: labPrescriptionGenerator(form),
		};
		sendIHMAnswersAndReport({ answers: form, basic_report })
			.then((r) => (window.location = "/app"))
			.catch((r) => console.log(r));
		//console.log(form, basic_report);
	};

	return (
		<div>
			{completed ? (
				!getUserId() && (
					<div className="col-md-6 offset-md-3">
						<Auth
							redirectUrl={null}
							onComplete={(data) => setTimeout(() => uploadIHMData(), 1500)}
						/>
					</div>
				)
			) : (
				<>
					{start ? (
						<Fade>
							<StepWizard nav={<Nav />}>
								<Step1 form={form} saveForm={onUpdateFrom} />
								<Step2 form={form} saveForm={onUpdateFrom} />
								<Step3
									form={form}
									saveForm={onUpdateFrom}
									submitSurvey={() => SetFormSubmit(true)}
								/>
							</StepWizard>
							<div
								className="text-muted text-right"
								style={{ fontSize: "0.8em" }}
							>
								Questions marked with <span className="text-danger">* </span>are
								mandatory. Others being optional.
							</div>

							<ReactTooltip multiline={true} effect="solid" />
						</Fade>
					) : (
						<IHMWelcome setStart={setStart} />
					)}
				</>
			)}
		</div>
	);
}

export const BottomNav = (props) => (
	<div className="my-4 ">
		<hr />
		<div className="mt-4 text-right">
			<Button
				outline
				color="secondary"
				className="mr-3"
				onClick={props.previousStep}
			>
				Go Back
			</Button>
			<Button color="primary" onClick={props.nextStep}>
				{props.nextLabel || "Save & Continue"}
			</Button>
		</div>
	</div>
);

export const CustomIHMSelect = ({ label, name, onChange }) => (
	<FormGroup>
		<Label for={name}>{label}</Label>
		<Input type="select" name={name} onChange={onChange}>
			<option value="0">N/A</option>
			<option value={IHMScores.MILD}>Mild</option>
			<option value={IHMScores.MODERATE}>Moderate</option>
			<option value={IHMScores.SEVERE}>Severe</option>
		</Input>
	</FormGroup>
);

/* 

g-pre-diab
g-pre-hd
g-pre-sa
g-pre-pc
g-pre-bp
g-pre-chol
g-pre-cc
g-pre-bc

g-age
g-sex
g-ht
g-wt
g-sbp
g-dbp
g-totchol
g-hdl
g-ldl
g-tryg


l-alc
l-excer
l-cigd
l-cigy
l-junk
l-red
l-diabp
l-heartp
l-asb


m-snore
m-snore-a
m-colo
m-colo-a
m-colo-b
m-colo-c
m-colo-d
m-bc
m-bc-a
m-bc-b

*/
