import { IHM_STATUS } from "data/config/variables";
import SectionHead from "pages/app/components/sectionHead";
import IHMRSteps from "pages/ihm/wizard";
import React, { Component } from "react";
import IHMReportBasic from "./basic";
import IHMReportAdvanced from "./advanced";
import { getIHMRecord } from "pages/ihm/wizard/network";

export default class UserMyHealth extends Component {
	constructor(props) {
		super(props);
		this.state = { status: IHM_STATUS.NONE };
		getIHMRecord()
			.then((r) => {
				const [rec] = r.data.ihm;
				if (rec) {
					const { status, basic_report, answers } = rec;
					this.setState({ status, basic_report, answers });
				}
			})
			.catch((r) => console.log(r));
	}

	render() {
		const { status, basic_report, answers } = this.state;
		return (
			<div>
				<div className="mainc container-fluid">
					<SectionHead
						title="My Pet Health Report" 
						createTxt="Retake Test"
						onCreateLinkClick={Boolean(status)}
						createLink="/pet-health-assessment/order"
					/>
					<div className="p-3 shadow-sm">
						{!status ? (
							<IHMRSteps />
						) : status === IHM_STATUS.PAID ? (
							<IHMReportAdvanced answers={answers} report={basic_report} />
						) : (
							<IHMReportBasic report={basic_report} />
						)}
					</div>
				</div>
			</div>
		);
	}
}
