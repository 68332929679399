import React, { Component } from "react";

export default class TalkToAVet extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    this.props.history.push("/talk-to-a-vet/order");
  }

  render() {
    return (
      <main>
        <div className="position-relative mt-5 pageTitle">
          <img
            className="img-fluid"
            src="/img/vetBanner.jpg"
            alt="inner page bg"
          />
          <div className="position-absolute w-100 text-center text-white page-tagline">
            <h1 className="w-70 m-auto pt-5 pb-5">Chat With A Vet for Free</h1>
          </div>
        </div>
        <div className="container py-5">
          <div className="row">
            <div className="col-sm-6">
              <h5
                className="mt-5 pt-5 text-black-50"
                style={{ lineHeight: "28px" }}
              >
                Chat for free with a vet and get your questions answered fast.
                Rest assured that our highly experienced care specialists are
                always on call ready to help you, day or night!
              </h5>
              <a
                href="#!"
                onClick={this.onClick}
                className="btn btn-primary btn-lg full-rounded mt-4 px-5"
              >
                Chat Now
              </a>
            </div>
            <div className="col-sm-6">
              <img
                src="/img/talktovet.jpg"
                className="img-fluid"
                alt="Online veterinarian consultation"
              />
            </div>
            <div className="clearfix" />
          </div>
          <h2 className="my-5 py-3 text-center">The Advantage</h2>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="d-flex justify-content-sm-between mb-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Quick and easy online scheduling
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Top Vets: Our vets are trusted experts offering vaccines,
                  diagnostics and advanced care.
                </div>
              </div>
              <div className="d-flex justify-content-sm-between my-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Peace of mind on a range of questions
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Consult from home without having to take time off work
                </div>
              </div>
              <div className="d-flex justify-content-sm-between my-3">
                <div className="p-3 border-left border-primary listBx fs-1 w-50 mr-3">
                  Saves you the costs of unnecessary travel
                </div>
                <div className="p-3 border-left border-primary listBx fs-1 w-50 ml-3">
                  Helps resolve minor issues before they become major ones
                </div>
              </div>
            </div>
            <div className="clearfix" />
            <div className="text-center mt-3 mx-auto">
              <a
                href="#!"
                onClick={this.onClick}
                className="btn btn-primary btn-lg full-rounded mt-4 px-5"
              >
                Chat For Free Now
              </a>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
